/// <reference path="../../typings/tsd.d.ts" />

import cst from './constants';
import Controller from './controller';
import FlatMap from './flat_map';
import VolumetricMap from './volumetric_map/volumetric_viz';
import * as _ from 'lodash';

class MapManager {

  private transitionInProgress: boolean = false;
  private transitionPromiseChain: Promise<any>;
  private activeMap: Miami.Map;
  private inactiveMap: Miami.Map;

  private header: HTMLElement;
  private sidebar: HTMLElement;

  constructor(private controller: Controller, private flat: FlatMap, private volumetric: VolumetricMap) {
    this.header = <HTMLElement>document.querySelector('header'); // TODO: refactor
    this.sidebar = <HTMLElement>document.querySelector('#storyline');

    this.controller.on(cst.event.TOTAL_STATE_UPDATE, this.onTotalStateUpdate, this);
    this.controller.on(cst.event.SECTION_CHANGED, this.onSectionChange, this);
    this.controller.on(cst.event.MAP_ZOOM_IN, this.onZoomIn, this);
    this.controller.on(cst.event.MAP_ZOOM_OUT, this.onZoomOut, this);
    this.controller.on(cst.event.MAP_MANUAL, this.onManualModeToggle, this);
    this.controller.on(cst.event.V_TOGGLE_IDLE, this.toggleIdle, this);
    this.controller.on(cst.event.WINDOW_RESIZED, this.updateMapContentOffsetValues, this);
  }

  private onTotalStateUpdate(state: Miami.State): void {
    if (this.transitionInProgress) return;
    if (!this.activeMap || !this.inactiveMap) {
      this.defineActiveMap(state);
      this.updateMapContentOffsetValues();
      this.activeMap.show(true);
      this.activeMap.onInitialSectionChange(state);
    } else {
      this.onSectionChange(state);
    }
  }

  private onSectionChange(state: Miami.State): void {
    if (this.transitionInProgress) return;
    let newActiveMap = this[cst.sectionActiveMap[state.section]];
    if (!_.eq(newActiveMap, this.activeMap)) {
      this.swapMaps(state);
    } else {
      this.activeMap.onSectionChange(state);
    }
  }

  private swapMaps(state: Miami.State): void {
    this.transitionInProgress = true;
    this.transitionPromiseChain = Promise.all([
        this.inactiveMap.prepareForActivation(state),
        this.activeMap.deactivate(state)
      ])
      .then(() => {
        this.inactiveMap.show(true);
        this.activeMap.show(false);
        return null;
      })
      .then(() => {
        return this.inactiveMap.activate(state);
      })
      .then(() => {
        [this.activeMap, this.inactiveMap] = [this.inactiveMap, this.activeMap];
        this.transitionInProgress = false;
        return null;
      })
      .catch((rejection: Miami.State) => {
        if (_.isError(rejection)) {
          throw rejection;
        }
        this.defineActiveMap(rejection);
        this.volumetric.interruptTransition();
        this.activeMap.show(true);
        this.inactiveMap.show(false);
        // XXX: this is such a mess
        setTimeout(() => { // HACK
          this.defineActiveMap(rejection); // HACK
          this.activeMap.onSectionChange(rejection);
          this.transitionInProgress = false;
        }, 100);
      });
  }

  private defineActiveMap(state: Miami.State): void {
    let activeMapName = cst.sectionActiveMap[state.section];
    this.activeMap = this[activeMapName];
    this.inactiveMap = activeMapName === cst.map.FLAT ? this[cst.map.VOLUMETRIC] : this[cst.map.FLAT];
  }

  private onZoomIn(): void {
    this.activeMap.zoomIn();
  }

  private onZoomOut(): void {
    this.activeMap.zoomOut();
  }

  private onManualModeToggle(state: Miami.State): void {
    this.activeMap.toggleManualMode(state);
  }

  private toggleIdle(state: Miami.State): void {
    const { section, idleMode } = state;
    const data = state.volData[section];
    if (idleMode) {
      if (this.transitionInProgress) {
        this.transitionPromiseChain.then(() => {
          // XXX: if you do mousemove while transitioning from one map to another
          // then it will be false but you will enter the idleMode and then all kind
          // of buggs
          if (!state.idleMode) return;
          this.volumetric.enterIdleMode(section, data)
        });
      } else {
        this.volumetric.enterIdleMode(section, data);
      }
    } else {
      this.volumetric.leaveIdleMode(section);
    }
  }

  private updateMapContentOffsetValues(): void {
    let offsets = {
      top: this.header.getBoundingClientRect().bottom,
      left: this.sidebar.getBoundingClientRect().right
    };
    this.activeMap.setContentOffset(offsets);
    this.inactiveMap.setContentOffset(offsets);
  }

}

export default MapManager;
