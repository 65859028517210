/// <reference path="../../../typings/tsd.d.ts" />

declare function require(module: string): any;
require('./PLYLoader');

import utils from '../utils';
import cst from '../constants';
import cstv from './volumetric_viz_constants';
import * as _ from 'lodash';

/*import components*/
import Tiles from './volumetric_viz_tiles';
import Blobs from './volumetric_viz_blob_layers';
import ExampleScheme from './ExampleScheme.ts';
import Connections from './volumetric_viz_connections';
import AreaLens from './AreaLens';
import MapControls from './MapControls';
import Controller from '../controller';

/*debug features*/
import DebugAxes = require('./ThreeDebugingAxes.ts');
let Stats = require('./stats.min');
let RendererStats = require('./threex.rendererstats');

class VolumetricMap implements Miami.Map {

  /*essentials*/
  private camera: THREE.PerspectiveCamera;
  private scene: THREE.Scene;
  private renderer: THREE.WebGLRenderer;
  private container: HTMLElement;
  private mapPlane: THREE.Plane;
  private raycaster = new THREE.Raycaster();

  /*components*/
  private tiles: Tiles;
  private tileGroup: THREE.Group;
  private blobs: Blobs;
  private blobGroup: THREE.Group;
  private connections: Connections;
  private connectionsGroup: THREE.Group;
  private exampleScheme: ExampleScheme;
  private areaLens: AreaLens;
  private controls: MapControls;
  private stats: any;
  private rendererStats: any;

  /*general state*/
  private debug = false;
  private _requestAnimationFrameId: number;
  private contentMargins = { top: 0, left: 0 };

  private exhibitPopup: HTMLElement;

  constructor(private containerSelector: string, private controller: Controller) {
    this.scene = new THREE.Scene();
    this.camera = new THREE.PerspectiveCamera(35, document.body.clientWidth / window.innerHeight, 0.001, 100000);
    this.renderer = new THREE.WebGLRenderer({
      antialias: true,
      preserveDrawingBuffer: true,
      logarithmicDepthBuffer: true
    });
    this.renderer.setClearColor(0xffffff);
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(document.body.clientWidth, window.innerHeight);
    this.mapPlane = new THREE.Plane(new THREE.Vector3(0, 1, 0), 0);

    this.container = <HTMLElement>document.querySelector(containerSelector);
    this.container.appendChild(this.renderer.domElement);

    // FIXME: magic knowledge about some DOM element
    this.exhibitPopup = document.getElementById('exhibit');

    /*components*/
    this.tileGroup = new THREE.Group();
    this.scene.add(this.tileGroup);
    this.tiles = new Tiles(this.tileGroup, {
      geoCenter: { long: -80.258694, lat: 25.749524 },
      geoBounds: { northWest: { lat: 26.749, long: -81.258 }, southEast: { lat: 24.749, long: -79.258 } },
      zoomLevels: [
        { maxCameraOffset: 40, value: 13 },
        { maxCameraOffset: 70, value: 12 },
        { maxCameraOffset: 110, value: 11 },
        { maxCameraOffset: Infinity, value: 10 }
      ]
    });

    this.blobGroup = new THREE.Group();
    this.scene.add(this.blobGroup);
    this.blobs = new Blobs(this.controller, this.blobGroup, 'volumetricMapBlobs');
    this.connectionsGroup = new THREE.Group();
    this.scene.add(this.connectionsGroup);
    this.connections = new Connections(this.controller, this.blobGroup, 'volumetricMapConnections');

    this.exampleScheme = new ExampleScheme('volumetricExampleScheme', this.controller);

    this.areaLens = new AreaLens(this.controller, this.scene, this.camera, this.mapPlane, this.container);

    /*controls*/
    this.controls = new MapControls(this.controller, this.camera, this.mapPlane, this.container);
    this.controls.mouseButtons = { PAN: THREE.MOUSE.LEFT, ZOOM: THREE.MOUSE.MIDDLE, ORBIT: THREE.MOUSE.RIGHT };
    this.controls.minZoom = 25;
    this.controls.maxZoom = 200;
    this.controls.maxPan = 75;
    this.controls.doubleClickZoom = true;
    // this.controls.hasInertia = true;

    /*debug features*/
    if (this.debug) {
      new DebugAxes(this.scene);
      this.stats = new Stats();
      this.stats.setMode(0);
      document.body.appendChild( this.stats.domElement );
      this.rendererStats = new RendererStats();
      document.body.appendChild(this.rendererStats.domElement);
    }

    /*subscribe to controller events*/
    this.controller.on(cst.event.V_DATASET_CHANGED, this.onDatasetChange, this);
    this.controller.on(cst.event.V_RANGE_CHANGED, this.onRangeChange, this);
    this.controller.on(cst.event.V_VISIBILITY_CHANGED, this.onVisibilityChange, this);
    this.controller.on(cst.event.V_AREA_LENS_TOGGLED, this.onAreaLensToggle, this);
    this.controller.on(cst.event.V_AREA_LENS_MOVED, this.onAreaLensMove, this);
    this.controller.on(cst.event.V_SCREENSHOT_TAKEN, this.onScreenshotTaken, this);
    this.controller.on(cst.event.V_EXPLORE_TOGGLED, this.onExploreModeToggle, this);
    this.controller.on(cst.event.WINDOW_RESIZED, _.debounce(this.onResize, 250, {leading: false, trailing: true}), this);

    /*control listeners*/
    this.container.addEventListener('mousedown', this.onMouseDown.bind(this));
    this.container.addEventListener('touchstart', this.onTouchStart.bind(this));
  }

  /*map core*/
  prepareForActivation(state: Miami.State): Promise<number> {
    return new Promise((resolve: Function, reject: Function) => {
      this.controller.on(cst.event.TOTAL_STATE_UPDATE, reject);
      this.controller.on(cst.event.SECTION_CHANGED, reject);
      this.setViewForSection(cstv.viewOptionsForTransition, state.section, cst.duration.INSTANT);
      resolve(null);
    });
  }

  deactivate(state: Miami.State): Promise<number> {
    return new Promise((resolve: Function, reject: Function) => {
      this.controller.on(cst.event.TOTAL_STATE_UPDATE, reject);
      this.controller.on(cst.event.SECTION_CHANGED, reject);
      this.setDataset(null);
      this.scene.remove(this.exampleScheme);
      this.onAreaLensToggle(false);
      this.setViewForSection(cstv.viewOptionsForTransition, state.section);
      this.controller.once(cst.event.V_CAMERA_TRANSITION_END, () => {
        // XXX: this timeout prevents blinking on map transition
        // but can possibly stop render loop while you are on volumetric map
        // (if you switch from vol to flat and back fast enough)
        // which breaks stuff.
        setTimeout(() => {
          // TODO: refactor. Right now just added check for current section
          if (cst.sectionActiveMap[state.section] === cst.map.VOLUMETRIC) return;
          window.cancelAnimationFrame(this._requestAnimationFrameId);
          resolve(null);
        }, cst.duration.SHORT_TRANSITION);
      });
    });
  }

  show(visible: boolean): void {
    this.container.style.visibility = visible ? 'visible' : 'hidden';
    if (visible) {
      this.update();
    } else {
      window.cancelAnimationFrame(this._requestAnimationFrameId);
    }
  }

  activate(state: Miami.State): Promise<number> {
    return new Promise((resolve: Function, reject: Function) => {
      this.controller.on(cst.event.TOTAL_STATE_UPDATE, reject);
      this.controller.on(cst.event.SECTION_CHANGED, reject);
      this.setViewForSection(cstv.viewOptionsForSection[state.section], state.section);
      this.controller.once(cst.event.V_CAMERA_TRANSITION_END, () => {
        this.applySectionSettings(state);
        resolve(null);
      });
    });
  }

  onInitialSectionChange(state: Miami.State): void {
    this.setViewForSection(cstv.viewOptionsForSection[state.section], state.section, cst.duration.INSTANT);
    this.applySectionSettings(state);
  }

  onSectionChange(state: Miami.State): void {
    this.setViewForSection(cstv.viewOptionsForSection[state.section], state.section);
    this.applySectionSettings(state);
  }

  zoomIn(): void {
    this.controls.zoomIn();
  }

  zoomOut(): void {
    this.controls.zoomOut();
  }

  setContentOffset(offset: Miami.MapContentOffset): void {
    this.contentMargins = offset;
  }

  interruptTransition(): void {
    this.controls.interruptTransition();
  }

  toggleManualMode(state: Miami.State): void {
    this.container.classList.toggle('manual-mode', state.mapManual);
    this.controls.isEnabled = state.mapManual;
    if (!state.mapManual) this.setViewForSection(cstv.viewOptionsForSection[state.section], state.section);
  }

  enterIdleMode(section: string, data: Miami.VolumetricData): void {
    this.controls.interruptTransition(); // XXX: maybe section view transition in progress
    this.setViewForSection(cstv.viewOptionsForSection[section], section, cst.duration.EXTRA_LONG_TRANSITION);
    // XXX: this is based on the fact that transition will occur
    // and the event will be fired even if the start and the end points are the same
    // see MapControls/startTransition method
    this.controller.once(cst.event.V_CAMERA_TRANSITION_END, () => {
      this.controller.changeVisibility(cstv.fullObjectVisibility);
      this.controller.changeDataset(this.completeDataset(section, data));
      this.controller.changeRange(cstv.fullTimeRange);
      this.controller.toggleAreaLens(true);
      this.areaLens.toggleHighlightsMode(true);
      this.controls.autoRotate();
      this.controller.play();
      this.exhibitPopup.classList.remove('hidden');
    });
  }

  leaveIdleMode(section: string): void {
    this.controls.interruptTransition();
    // XXX: in case enter was interrupted
    this.controller.removeAllListeners(cst.event.V_CAMERA_TRANSITION_END);
    // this.setViewForSection(cstv.viewOptionsForSection[section], section, cst.duration.MEDIUM_TRANSITION);
    this.areaLens.toggleHighlightsMode(false);
    this.controller.stop();
    this.exhibitPopup.classList.add('hidden');
  }

  /*controller events*/
  private onMouseDown(e: MouseEvent): void {
    if (this.areaLens.isActive) {
      let mouse = utils.toNormalizedDeviceCoordinates(e.clientX, e.clientY);
      this.raycaster.setFromCamera(mouse, this.camera);
      let intersection = this.raycaster.ray.intersectPlane(this.mapPlane);
      if (!intersection) return;
      let distanceToAreaLensCenter = new THREE.Vector3().subVectors(intersection, this.areaLens.currentPosition).length();
      if (distanceToAreaLensCenter <= this.areaLens.radius) {
        this.areaLens.onPointerStart();
      } else {
        this.controls.onMouseDown(e);
      }
    } else {
      this.controls.onMouseDown(e);
    }
  }

  private onTouchStart(e: TouchEvent): void {
    if (this.areaLens.isActive) {
      let touch = utils.toNormalizedDeviceCoordinates(e.touches[0].clientX, e.touches[0].clientY);
      this.raycaster.setFromCamera(touch, this.camera);
      let intersection = this.raycaster.ray.intersectPlane(this.mapPlane);
      if (!intersection) return;
      let distanceToAreaLensCenter = new THREE.Vector3().subVectors(intersection, this.areaLens.currentPosition).length();
      if (distanceToAreaLensCenter <= this.areaLens.radius) {
        this.areaLens.onPointerStart();
      } else {
        this.controls.onTouchStart(e);
      }
    } else {
      this.controls.onTouchStart(e);
    }
  }

  /*event handlers*/
  private onDatasetChange(state: Miami.State): void {
    let dataset = state.volData[state.section];
    this.setDataset(dataset);
  }

  private onRangeChange(state: Miami.State): void {
    let range = state.volRange[state.section];
    let integerRange = utils.dateToIntegers(range);
    this.blobs.setRange(integerRange);
    this.connections.setRange(integerRange);
  }

  private onVisibilityChange(state: Miami.State): void {
    let visibility = state.volVisibility[state.section];
    this.blobs.setVisibility(visibility.blobs);
    this.connections.setVisibility(visibility.connections);
  }

  private onExploreModeToggle(state: Miami.State): void {
    let status = state.volExploreMode[state.section];
    this.container.classList.toggle('manual-mode', status);
    this.controls.isEnabled = status;
  }

  private onAreaLensToggle(enabled: boolean): void {
    this.areaLens.toggle(enabled);
    this.connections.onAreaLensToggle(enabled);
  }

  private onAreaLensMove(position: THREE.Vector3): void {
    this.connections.onAreaLensMove(position);
  }

  private onScreenshotTaken(): void {
    window.open(this.renderer.domElement.toDataURL('image/png'));
  }

  private onResize(): void {
    this.camera.aspect = document.body.clientWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(document.body.clientWidth, window.innerHeight);
  }

  /*volumetric methods*/
  private setViewForSection(view: Miami.VolMapViewOptions, section: string, duration: number = cst.duration.LONG_TRANSITION): void {
    this.controls.startTransition(view, duration, {
      margins: cst.contentMarginsForSections[section],
      marginValues: this.contentMargins
    });
  }

  private applySectionSettings(state: Miami.State): void {
    this.onDatasetChange(state);
    this.onRangeChange(state);
    this.onVisibilityChange(state);
    this.onExploreModeToggle(state);
    this.onAreaLensToggle(cstv.areaLensStatusForSection[state.section]);
    if (state.section === cst.section.SEMANTIC) {
      this.scene.add(this.exampleScheme);
    } else {
      this.scene.remove(this.exampleScheme);
    }
  }

  private setDataset(dataset: Miami.VolumetricData): void {
    let setSubsetTop: string = (_.get(dataset, 'top.set') && _.get(dataset, 'top.subset')) ? utils.datasetName(dataset.top.set, dataset.top.subset) : null;
    let setSubsetBottom: string = (_.get(dataset, 'bottom.set') && _.get(dataset, 'bottom.subset')) ? utils.datasetName(dataset.bottom.set, dataset.bottom.subset) : null;
    this.blobs.setDataset(setSubsetTop, setSubsetBottom);
    this.connections.setDataset(setSubsetTop, setSubsetBottom);
    this.areaLens.changeColor(setSubsetTop, setSubsetBottom);
  }

  private update(): void {
    this._requestAnimationFrameId = window.requestAnimationFrame(this.update.bind(this));
    // this.stats.begin();
    this.tiles.update(this.controls.current.target, this.controls.cameraOffsetLength);
    if (this.areaLens.isActive) this.areaLens.takeCorrectSide();
    this.renderer.render(this.scene, this.camera);
    // this.stats.end();
    // this.rendererStats.update(this.renderer);
  }

  private completeDataset(section: string, data: Miami.VolumetricData): Miami.VolumetricData {
    const { top, bottom } = data;
    return {
      top: top.set ? top : { set: this.complementarySet(bottom.set), subset: bottom.subset,  },
      bottom: bottom.set ? bottom : { set: this.complementarySet(top.set), subset: top.subset,  },
    };
  }

  private complementarySet(set: string): string {
    const { MIRACLE, HOODS } = cstv.sets;
    return set === MIRACLE ? HOODS : MIRACLE;
  }
}

export default VolumetricMap;
