/// <reference path="../../typings/tsd.d.ts" />
'use strict';
import * as _ from 'lodash';
import qs = require('qs');
import cst from './constants';
import Controller from './controller';

class Router {

  constructor(private controller: Controller) {
    this.controller.on(cst.event.FULLY_INITIALIZED, this.onFullyInitialized, this);
  }

  getStateFromUri(): Miami.StateSection {
    let path = window.location.pathname.split('/');
    let parsedSection = path[path.length - 1];
    let parsedUri = qs.parse(atob(window.location.search.slice(1)));

    return this.validateState(parsedUri, parsedSection);
  }

  onFullyInitialized(): void {
    this.controller.on(cst.event.SECTION_CHANGED, this.push, this);
    this.controller.on(cst.event.V_EXPLORE_TOGGLED, this.push, this);

    this.controller.on(cst.event.TOTAL_STATE_UPDATE, this.replace, this);
    this.controller.on(cst.event.F_LAYER_CHANGED, this.replace, this);
    this.controller.on(cst.event.V_DATASET_CHANGED, this.replace, this);
    this.controller.on(cst.event.V_RANGE_CHANGED, this.replace, this);
    this.controller.on(cst.event.V_VISIBILITY_CHANGED, this.replace, this);

    window.addEventListener('popstate', (e: PopStateEvent) => {
      if (e.state !== null) this.controller.totalStateChange(e.state);
    });
  }

  push(state: Miami.State): void {
    let uri: string = this.stringifyState(state);
    let delimeter: string = uri.length > 0 ? '?' : '';
    window.history.pushState(state, '', state.section + delimeter + uri);
  }

  replace(state: Miami.State): void {
    let uri: string = this.stringifyState(state);
    let delimeter: string = uri.length > 0 ? '?' : '';
    window.history.replaceState(state, '', state.section + delimeter + uri);
  }

  private stringifyState(state: Miami.State): string {
    let filteredState: Miami.StateSection = {};
    let section = state.section;

    _.forOwn(state, (value, prop) => {
      switch (prop) {
        case 'section': filteredState.section = null; break;
        default: filteredState[prop] = state[prop][section];
      }
    });

    return btoa(qs.stringify(filteredState));
  }

  private validateState(parsedState: any, parsedSection: string): Miami.StateSection {
    let validState: Miami.StateSection = {
      section: this.validateSection(parsedSection)
    };

    _.forOwn(parsedState, (value, prop) => {
      switch (prop) {
        case 'flatLayers': validState.flatLayers = this.validateFlatLayers(parsedState[prop]); break;
        case 'volData': validState.volData = this.validateVolData(parsedState[prop]); break;
        case 'volRange': validState.volRange = this.validateRange(parsedState[prop]); break;
        case 'volVisibility': validState.volVisibility = this.validateVolVisibility(parsedState[prop]); break;
        case 'volExploreMode': validState.volExploreMode = this.validateExploreMode(parsedState[prop]); break;
        default: return;
      }
    });

    return validState;
  }

  private validateSection(input: string): string {
    return _.includes(cst.sectionsInOrder, input) ? input : undefined;
  }

  private validateFlatLayers(input: string[]): string[] {
    return input;
  }

  private validateVolData (input: Miami.VolumetricData): Miami.VolumetricData {
    return input;
  }

  private validateVolVisibility (input: Miami.VolumetricObjectsVisibility): Miami.VolumetricObjectsVisibility {
    return input;
  }

  private validateRange(input: string[]): Date[] {
    return input.map(date => new Date(date));
  }

  private validateExploreMode(input: string): boolean {
    return input === 'true' ? true : false;
  }
}

export default Router;
