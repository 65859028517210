/// <reference path="../../typings/tsd.d.ts" />

import * as _ from 'lodash';

export function isToggleRadioButton(element: Element): boolean {
  let input = <HTMLInputElement>element;

  return !_.isUndefined(input.dataset['toggle']) && input.type === 'radio';
}

export function handleToggleRadioButtonGroup(element: Element): void {
  let input = <HTMLInputElement>element;
  let neighbours = document.querySelectorAll(`[name=${input.name}]:not(#${input.id})`);

  if (input.checked.toString() !== input.dataset['toggle']) {
    input.dataset['toggle'] = input.checked.toString();
    _.each(neighbours, neighbour => { neighbour['dataset'].toggle = !input.checked.toString(); });
  } else {
    input.checked = !input.checked;
    input.dataset['toggle'] = input.checked.toString();
  }
}
