/// <reference path="../../typings/tsd.d.ts" />

import * as _ from 'lodash';
import EventEmitter = require('eventemitter3');
import Router from './router';
import cst from './constants';

class Controller extends EventEmitter {
  private router: Router;

  constructor(private state: Miami.State, private components?: any) {
    super();
    this.router = new Router(this);
  }

  totalStateUpdate(state: Miami.StateSection): void {
    if (_.get(state, 'section')) this.state.section = state.section;

    _.forOwn(state, (value, key) => {
      if (key === 'section' || _.isUndefined(value)) return;
      if (typeof value === 'object') {
        // HACK: see line 70
        _.merge(this.state[key][this.state.section], value);
      } else {
        this.state[key][this.state.section] = value;
      }
    }, this);

    this.emit(cst.event.TOTAL_STATE_UPDATE, this.state);
    if (this.state.idleModeEnabled) this.emit(cst.event.V_INIT_IDLE, this.state);
  }

  totalStateChange(state: Miami.State): void {
    this.state = state;
    this.emit(cst.event.TOTAL_STATE_UPDATE, this.state);
  }

  changeSection(section: string, snap: boolean = true): void {
    if (!this.componentsReady()) return;
    if (!section || section === this.state.section) return;
    // XXX: protects from an absurd situation apparently happening in Firefox
    // if you scroll fast and click on Explore button, you enter explore
    // and THEN change section to Credits (because FF is very-very slow)
    // if (this.state.volExploreMode[this.state.section]) return;
    if (this.state.idleMode) return;
    this.toggleExplore(false);
    this.mapManual(false);
    this.state.section = section;
    this.emit(cst.event.SECTION_CHANGED, this.state, snap);
  }

  /*flat map actions*/
  changeLayer(layers: string[]): void {
    let section = this.state.section;
    if (!layers || _.eq(layers, this.state.flatLayers[section])) return;
    this.state.flatLayers[section] = layers;
    this.emit(cst.event.F_LAYER_CHANGED, this.state);
  }

  /*volumetric map actions*/
  changeDataset(dataParams: Miami.VolumetricData|Miami.SetSubset): void {
    let section = this.state.section;
    let newVolData: Miami.VolumetricData = {};
    _.merge(newVolData, this.state.volData[section], dataParams);
    if (_.eq(newVolData, this.state.volData[section])) return;
    // HACK: Intentionally mutate state to preserve the original object
    // which is also used by Summary and Credits so they feel like a single section
    // and there is no ugly switching between the default preset and the current
    // user's preset while scrolling Summary -> Credits
    // (see volumetric_viz_constants:61)
    _.assign(this.state.volData[section], dataParams);
    this.emit(cst.event.V_DATASET_CHANGED, this.state);
  }

  changeRange(range: Date[]): void {
    let section = this.state.section;
    if (!range || _.eq(range, this.state.volRange[section])) return;
    // HACK: Same sort of hack as above
    this.state.volRange[section][0] = range[0];
    this.state.volRange[section][1] = range[1];
    this.emit(cst.event.V_RANGE_CHANGED, this.state);
  }

  changeVisibility(visibilityParams: Miami.VolumetricObjectsVisibility|Miami.TopBottom): void {
    let section = this.state.section;
    if (!visibilityParams) return;
    let newVisibility: Miami.VolumetricObjectsVisibility = {};
    _.merge(newVisibility, this.state.volVisibility[section], visibilityParams);
    if (_.eq(newVisibility, this.state.volVisibility[section])) return;
    // HACK: Same sort of hack as above
    _.assign(this.state.volVisibility[section], newVisibility);
    this.emit(cst.event.V_VISIBILITY_CHANGED, this.state);
  }

  changeHighlight(set: string, subset: string): void {
    this.emit(cst.event.V_HIGHLIGHT_CHANGED, set, subset);
  }

  toggleExplore(status: boolean): void {
    let section = this.state.section;
    if (this.state.idleMode) return;
    if (status === this.state.volExploreMode[section]) return;
    this.state.volExploreMode[section] = status;
    if (status === false) this.emit(cst.event.V_STOP);
    this.emit(cst.event.V_EXPLORE_TOGGLED, this.state);
    this.emit(cst.event.V_AREA_LENS_TOGGLED, false);
  }

  toggleAreaLens(status: boolean): void {
    this.emit(cst.event.V_AREA_LENS_TOGGLED, status);
  }

  moveAreaLens(position: THREE.Vector3): void {
    this.emit(cst.event.V_AREA_LENS_MOVED, position);
  }

  screenshot(): void {
    this.emit(cst.event.V_SCREENSHOT_TAKEN);
  }

  endCameraTransition(): void {
    this.emit(cst.event.V_CAMERA_TRANSITION_END);
  }

  play(): void {
    this.emit(cst.event.V_PLAY);
  }

  stop(): void {
    this.emit(cst.event.V_STOP);
  }

  updateComponentState(component: string, ready: boolean): void {
    this.components[component] = ready;
    if (this.componentsReady()) this.emitInitialState();
  }

  componentsReady(): boolean {
    return _.every(this.components);
  }

  zoomIn(): void {
    if (!this.state.mapManual) this.mapManual(true);
    this.emit(cst.event.MAP_ZOOM_IN);
  }

  zoomOut(): void {
    if (!this.state.mapManual) this.mapManual(true);
    this.emit(cst.event.MAP_ZOOM_OUT);
  }

  mapManual(enable: boolean): void {
    if (this.state.mapManual === enable) return;
    if (this.state.volExploreMode[this.state.section]) return;
    this.state.mapManual = enable;
    this.emit(cst.event.MAP_MANUAL, this.state);
  }

  mapZoomLimit(limits: Miami.ZoomLimit): void {
    this.emit(cst.event.MAP_ZOOM_LIMIT, limits);
  }

  /*exhibition mode*/
  toggleIdleMode(status: boolean): void {
    if (status === this.state.idleMode) {
      console.warn('For some reason toggleIdleMode was called with the same status ' + status);
      return;
    }
    this.state.idleMode = status;
    this.emit(cst.event.V_TOGGLE_IDLE, this.state);
  }

  recalculateSections(): void {
    this.emit(cst.event.RECALCULATE_SECTIONS);
  }

  scrollToSection(section?: string): void {
    if (!section) section = this.state.section;
    this.emit(cst.event.SCROLL_TO_SECTION, section);
  }

  private emitInitialState(): void {
    let initialState: Miami.StateSection = this.router.getStateFromUri();
    this.totalStateUpdate(initialState);

    window.addEventListener('resize', this.windowResized.bind(this));

    this.emit(cst.event.FULLY_INITIALIZED);
  }

  private windowResized(): void {
    this.emit(cst.event.WINDOW_RESIZED);
  }
}

export default Controller;
