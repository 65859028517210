/// <reference path="../../typings/tsd.d.ts" />

import * as _ from 'lodash';
import cst from './constants';
import cstf from './flat_map_constants';
import Controller from './controller.ts';

declare function require(name: string): any;
let lInstagramLayer = require('./LeafletInstagram.js');
let lPopupLayer = require('./LeafletPopupLayer.js');

/*flat map view*/
class FlatMap implements Miami.Map {

  private isZooming: boolean;

  private container: HTMLElement;
  private map: L.mapbox.Map;
  private layers: any = {};
  private layersActive: L.LayerGroup<L.ILayer>;
  private layersPending: any[] = [];
  private layersPendingNotLoaded: string[] = [];

  /*debug*/
  private debug: boolean = false;
  private activeBoundsFrame: L.Rectangle;
  private maxBoundsFrame: L.Rectangle;
  private paddingMarker: L.Marker;
  private latLongMarker: L.Marker;

  /*etc*/
  private _throttledOnLayersChange: Function;
  private contentMargins = { top: 0, left: 0 };

  /*defaults*/
  private default = {
    panOptions: {
      duration: cst.duration.MEDIUM_TRANSITION / 1000,
      easeLinearity: 0.25
    },
    padding: { top: 0.06, left: 0.06, bottom: 0.06, right: 0.06 },
    maxBounds: cstf.geobounds.UNIVERSE,
    maxZoom: 18,
    minZoom: 0
  };

  constructor( private containerSelector: string,  private controller: Controller) {
    this.container = <HTMLElement>document.querySelector(containerSelector);
    this.map = L.mapbox.map(containerSelector.slice(1), null, {
      zoomAnimationThreshold: 10,
      zoomControl: false,
      closePopupOnClick: false,
      scrollWheelZoom: false,
      dragging: false,
      touchZoom: false,
      doubleClickZoom: false,
      boxZoom: false
      // keyboard
      // bounceAtZoomLimits
    });
    this.map.on('contextmenu', () => null)
      .on('zoomstart', () => { this.isZooming = true; })
      .on('zoomend', this.onZoomEnd.bind(this));

    if (this.map.tap) {
      this.map.tap.disable();
    }

    this.layers.base = L.mapbox.tileLayer('mapbox.light', {
      detectRetina: true
      // reuseTiles: false
      // unloadInvisibleTiles: true,
      // updateWhenIdle: true,
    });
    this.layers.base.addTo(this.map);

    L.control.scale({ imperial: false })
      .setPosition('bottomright')
      .addTo(this.map);

    this._throttledOnLayersChange = _.throttle(this.onLayersChange, cst.duration.MEDIUM_TRANSITION);
    this.controller.on(cst.event.F_LAYER_CHANGED, this._throttledOnLayersChange, this);

    if (this.debug) {
      this.showLatLongDebugger();
    }

    this.createAllLayers();
  }

  /*activation and deactivation api*/
  prepareForActivation(state: Miami.State): Promise<number> {
    return new Promise((resolve, reject) => {
      this.controller.on(cst.event.TOTAL_STATE_UPDATE, reject);
      this.controller.on(cst.event.SECTION_CHANGED, reject);

      this.setView(cstf.viewOptionsForTransition, {
        margins: cst.contentMarginsForSections[state.section],
        reset: true
      });

      if (this.isZooming) {
        this.map.once('zoomend', () => { resolve(null); });
      } else {
        resolve(null);
      }
    });
  }

  deactivate(state: Miami.State): Promise<number> {
    return new Promise((resolve, reject) => {
      this.controller.on(cst.event.TOTAL_STATE_UPDATE, reject);
      this.controller.on(cst.event.SECTION_CHANGED, reject);

      this.setLayers([]);
      this.setView(cstf.viewOptionsForTransition, { margins: cst.contentMarginsForSections[state.section] });

      if (this.isZooming) {
        this.map.once('zoomend', () => {
          window.setTimeout(() => {
            resolve(null);
          },
          cst.duration.MEDIUM_TRANSITION);
        });
      } else {
        window.setTimeout(() => {
          resolve(null);
        }, cst.duration.MEDIUM_TRANSITION);
      }
    });
  }

  show(visible: boolean): void {
    this.container.style.visibility = visible ? 'visible' : 'hidden';
  }

  activate(state: Miami.State): Promise<number> {
    return new Promise((resolve, reject) => {
      this.controller.on(cst.event.TOTAL_STATE_UPDATE, reject);
      this.controller.on(cst.event.SECTION_CHANGED, reject);
      this.onSectionChange(state);
      if (this.isZooming) {
        this.map.once('zoomend', () => { resolve(); });
      } else {
        resolve(null);
      }
    });
  }

  /*general api*/
  onInitialSectionChange(state: Miami.State): void {
    this._throttledOnLayersChange(state);
  }

  onSectionChange(state: Miami.State): void {
    this._throttledOnLayersChange(state);
  }

  onLayersChange(state: Miami.State): void {
    /*clear all asynchronous stuff*/
    this.layersPending = [];
    this.layersPendingNotLoaded = []; // Clear any previously queued layers
    this.map.removeEventListener('viewreset', this.addLayersPending, this);

    this.setView(this.getViewOptions(state), {
      margins: cst.contentMarginsForSections[state.section]
    });
    // HACK: To display inactive hoods and preserve
    // all the fragile things that depend on first layer in array
    // like components that check buttons and change layout (meh)
    // we just add more layers to all layers that have such feature
    // Better solution is just make the whole thing with React or something
    let layerNames = state.flatLayers[state.section];
    let firstLayer = layerNames[0];
    if (cstf.shadowLayers[firstLayer]) {
      this.setLayers([firstLayer].concat(cstf.shadowLayers[firstLayer]));
    } else {
      this.setLayers(layerNames);
    }
  }

  toggleManualMode(state: Miami.State): void {
    this.container.classList.toggle('manual-mode', state.mapManual);
    if (state.mapManual) {
      this.map.scrollWheelZoom.enable();
      this.map.dragging.enable();
      this.map.touchZoom.enable();
      this.map.doubleClickZoom.enable();
      this.map.boxZoom.enable();
    } else {
      this.setView(this.getViewOptions(state), {
        margins: cst.contentMarginsForSections[state.section]
      });
      this.map.scrollWheelZoom.disable();
      this.map.dragging.disable();
      this.map.touchZoom.disable();
      this.map.doubleClickZoom.disable();
      this.map.boxZoom.disable();
    }
  }

  zoomIn(): void {
    this.map.zoomIn();
  }

  zoomOut(): void {
    this.map.zoomOut();
  }

  setContentOffset(offset: Miami.MapContentOffset): void {
    this.contentMargins = offset;
  }

  private setLayers(layerNames: string[]): void {
    /*convert to layer object array*/
    let newLayers = layerNames.filter(name => {
        if (!this.layers[name]) this.layersPendingNotLoaded.push(name);
        return this.layers[name];
      })
      .map(name => { return this.layers[name]; });
    /*remove*/
    this.layersActive.eachLayer(layer => {
      if (_.contains(newLayers, layer)) {
        _.remove(newLayers, l => { return _.eq(layer, l); });
      } else {
        this.layersActive.removeLayer(layer);
      }
    });
    this.layersPending = newLayers;
    /*add new*/
    if (this.isZooming) {
      this.map.once('zoomend', this.addLayersPending, this);
    } else {
      this.addLayersPending();
    }
  }

  private addLayersPending(): void {
    for (let layer of this.layersPending) {
      layer.addTo(this.layersActive);
    }
  }

  private setView(view: Miami.FlatMapViewOptions, options: { margins: { top: boolean, left: boolean }, reset?: boolean }): void {
    let mapRect = this.container.getBoundingClientRect();
    let isReset = _.isUndefined(options.reset) ? false : options.reset;
    /*general options*/
    this.map.options.maxZoom = view.maxZoom || this.default.maxZoom;
    this.map.options.minZoom = view.minZoom || this.default.minZoom;
    this.map.setMaxBounds(view.maxBounds || this.default.maxBounds);
    /*setting the view*/
    if (view.center) { // if view is specified by center point
      let center = this.viewCenterWithOffset(view.center, view.specificZoom, options.margins);
      this.map.setView(center, view.specificZoom, {
        reset: isReset,
        pan: this.default.panOptions
      });
    } else if (view.bounds && view.maxZoom) { // if view is specified by bounds to fit
      let contentHeight = options.margins.top ? mapRect.height - this.contentMargins.top : mapRect.height;
      let contentWidth = options.margins.left ? mapRect.width - this.contentMargins.left : mapRect.width;

      let pad = view.padding || this.default.padding;
      let paddingTopLeft = L.point(pad.left * contentWidth + this.contentMargins.left, pad.top * contentHeight + this.contentMargins.top);
      let paddingBottomRight = L.point(pad.right * contentWidth, pad.bottom * contentHeight);

      this.map.fitBounds(view.bounds, {
        reset: isReset,
        maxZoom: view.maxZoom,
        paddingTopLeft: paddingTopLeft,
        paddingBottomRight: paddingBottomRight,
        pan: this.default.panOptions,
        animate: !isReset
      });

      if (this.debug) this.debugViewOptions(paddingTopLeft, view.bounds, view);
    } else {
      throw 'Bad map view preset';
    }
  }

  private getViewOptions(state: Miami.State) {
    const { section } = state;
    let layerNames = state.flatLayers[section];
    let firstLayer = layerNames[0];
    return cstf.viewOptionsForLayers[firstLayer] || cstf.viewOptionsForSections[section];
  }

  private viewCenterWithOffset(center: L.LatLng, zoom: number, margins: { top: boolean, left: boolean }): L.LatLng {
    let point = this.map.project(center, zoom);
    let left = margins.left ? this.contentMargins.left / 2 : 0;
    let top = margins.top ? this.contentMargins.top / 2 : 0;
    let pointWithMargins = point.subtract(L.point(left, top));
    return this.map.unproject(pointWithMargins, zoom);
  }

  private onZoomEnd(): void {
    this.isZooming = false;
    let zoom = this.map.getZoom();
    this.controller.mapZoomLimit({
      in: zoom === this.map.getMaxZoom(),
      out: zoom === this.map.getMinZoom()
    });
  }

  /*debug features*/
  private showLatLongDebugger(): void {
    this.latLongMarker = L.marker(L.latLng(25.7485, -80.27125), { draggable: true })
      .addTo(this.map)
      .on('dragend', () => {
        console.log(this.latLongMarker.getLatLng());
      });
  }

  private debugViewOptions(paddingTopLeft: L.Point, bounds: L.LatLngBounds, viewOptions: Miami.FlatMapViewOptions): void {
    if (typeof this.paddingMarker === 'undefined') {
      this.paddingMarker = L.marker(this.map.containerPointToLatLng(paddingTopLeft)).addTo(this.map);
    } else {
      this.paddingMarker.setLatLng(this.map.containerPointToLatLng(paddingTopLeft));
    }
    if (typeof this.activeBoundsFrame === 'undefined') {
      this.activeBoundsFrame = L.rectangle(bounds, { color: '#559955', weight: 2, fill: false });
      this.activeBoundsFrame.addTo(this.map);
    } else {
      this.activeBoundsFrame.setBounds(bounds);
    }
    if (typeof this.maxBoundsFrame === 'undefined') {
      this.maxBoundsFrame = L.rectangle(viewOptions.maxBounds, { color: '#995555', weight: 2, fill: false });
      this.maxBoundsFrame.addTo(this.map);
    } else {
      this.maxBoundsFrame.setBounds(viewOptions.maxBounds);
    }
  }

  /*layer creation*/
  private createAllLayers(): void {
    // adding a group for all other layers
    this.layersActive = L.layerGroup();
    this.layersActive.addTo(this.map);
    // Creating all the layers
    this.initLayer('all_tweets_blue_small', L.imageOverlay, './images/tweets/all_tweets_blue_small.png', cstf.geobounds.MIAMI);
    this.initLayer('all_tweets_gray', L.imageOverlay, './images/tweets/all_tweets_gray.png', cstf.geobounds.ALL_TWEETS);

    d3.json('./data/business.json').get((error, data) => {
      this.initMarkerLayer('business_map', cstf.BUSINESS_ICONS, data);
    });

    this.initLayer('instagram', lInstagramLayer, cstf.instagramPhotos);

    this.initLayer('old_coral_gables', L.imageOverlay, './images/coralgables.png', cstf.geobounds.OLD_GABLES);

    const smallScreen = document.body.clientWidth < 1400;
    const tweetIcon = new L.Icon({
      iconUrl: './images/full-tweet-illustration.png',
      iconRetinaUrl: './images/full-tweet-illustration@2x.png',
      iconSize: [710, 365].map(x => smallScreen ? x * 0.836 : x) as [number, number],
      iconAnchor: [355, 365].map(x => smallScreen ? x * 0.836 : x) as [number, number],
      className: 'tweet-example-illustration'
    });

    // Warning: fake data
    this.initLayer('sample_tweet', L.marker, L.latLng(25.7499, -80.258), {
      icon: tweetIcon,
      clickable: false,
      keyboard: false,
      alt: 'Tweet example',
    });

    let zoneNames: string[] = ['food', 'events', 'sports', 'entertainment', 'internet', 'work', 'music'];
    for (let i: number = 0; i < zoneNames.length; i++) {
      let zoneName: string = 'zone-' + zoneNames[i];
      let path: string = './images/zones/' + zoneNames[i] + '.svg';
      this.initLayer(zoneName, L.imageOverlay, path, L.latLngBounds(L.latLng(25.7441, -80.2686), L.latLng(25.7562, -80.2473)));
    }

    this.initHeatLayer(
      'heat-positive',
      'miami_positive.csv',
      { 0.2: '#edf8e9', 0.4: '#bae4b3', 0.6: '#74c476', 0.8: '#31a354', 1: '#006d2c' }
    );
    this.initHeatLayer(
      'heat-negative',
      'miami_negative.csv',
      { 0.2: '#fee5d9', 0.4: '#fcae91', 0.6: '#fb6a4a', 0.8: '#de2d26', 1: '#a50f15' }
    );

    /*neighborhoods*/
    this.initLayer('all_tweets_blue', L.imageOverlay, './images/tweets/all_tweets_blue.png', cstf.geobounds.ALL_TWEETS);

    this.initLayer('coral_gables', L.imageOverlay, './images/tweets/coral_gables.png', cstf.geobounds.HOOD_CORAL_GABLES);

    this.initHoodLayer(cstf.hoods.WYNWOOD, 'hood_wynwood_big.png', cstf.geobounds.HOOD_WYNWOOD);
    this.initHoodLayer(cstf.hoods.MIDTOWN_MIAMI, 'hood_midtown_big.png', cstf.geobounds.HOOD_MIDTOWN);
    this.initHoodLayer(cstf.hoods.COCOWALK, 'hood_cocowalk_big.png', cstf.geobounds.HOOD_COCOWALK);
    this.initHoodLayer(cstf.hoods.MIRACLE_MILE, 'hood_miracle_mile_big.png', cstf.geobounds.HOOD_MIRACLE_MILE);
    this.initHoodLayer(cstf.hoods.MERRICK_PARK, 'hood_merrick_big.png', cstf.geobounds.HOOD_MERRICK);
    this.initHoodLayer(cstf.hoods.DESIGN_DISTRICT, 'hood_design_big.png', cstf.geobounds.HOOD_DESIGN);
    this.initHoodLayer(cstf.hoods.DADELAND_MALL, 'hood_dadeland.png', cstf.geobounds.HOOD_DADELAND);

    this.initLayer('hood_popups', lPopupLayer, {
      data: cstf.HOOD_POPUPS,
      onClick: (dataset: any): void => {
        this.controller.changeLayer([dataset.hood]);
      }
    });

  }

  private initLayer(name: string, lConstructor: any, ...args: any[]): void {
    this.layers[name] = lConstructor.apply(null, args);
    let i: number = this.layersPendingNotLoaded.indexOf(name);
    if (i > -1) {
      this.layers[name].addTo(this.layersActive);
      this.layersPendingNotLoaded.splice(i, 1);
    }
  }

  private initHeatLayer(name: string, fileName: string, colors: any): void {
    d3.text('./data/' + fileName, (file: any): void => {
      let data: any[] = d3.csv.parseRows(file);
      data.shift(); // strip top row, dangerous
      for (let i = data.length - 1; i >= 0; i--) {
        data[i][0] = +data[i][0];
        data[i][1] = +data[i][1];
      }
      this.initLayer(name, L.heatLayer, data, { radius: 4, blur: 1, maxZoom: 18, gradient: colors });
    });
  }

  private initMarkerLayer(name: string, icons: {[name: string]: L.Icon|L.DivIcon}, data: { lat: number, lon: number, type: string }[]): void {
    let markerArray: L.Marker[] = [];
    for (var d of data) {
      let marker = L.marker([d.lat, d.lon], { icon: icons[d.type] });
      markerArray.push(marker);
    }
    this.initLayer(name, L.featureGroup, markerArray);
  }

  /**
   * Creates two layers per hood: active and inactive
   */
  private initHoodLayer(name: string, imageUrl: string, bounds: L.LatLngBounds) {
    this.initHoodLayerPart('selected', name, imageUrl, bounds);
    this.initHoodLayerPart('not-selected', name, imageUrl, bounds);
  }

  private initHoodLayerPart(type: string, name: string, imageUrl: string, bounds: L.LatLngBounds) {
    const icon = L.divIcon({
      html: name.replace('_', '&nbsp;'),
      className: type === 'selected' ? 'hood-label is-selected' : 'hood-label',
      iconSize: ['auto', 'auto'] as any // HACK: because it is not a point
    });

    const titleMarker = L.marker(bounds.getCenter(), { icon });
    if (type === 'not-selected') {
      titleMarker.on('click', () => this.controller.changeLayer([name]));
    }

    const overlayUrl = type === 'selected'
      ? `./images/tweets/neighbourhoods/${imageUrl}`
      : `./images/tweets/neighbourhoods_inactive/${imageUrl}`;

    const overlay = L.imageOverlay(overlayUrl, bounds);

    const layerName = type === 'selected' ? name : name + cstf.inactiveLayerPostfix;
    this.initLayer(layerName, L.layerGroup, [overlay, titleMarker]);
  }

}

export default FlatMap;
