/// <reference path="../../typings/tsd.d.ts" />

import Chart from './chart';
import cst from './constants';
import * as _ from 'lodash';

class NeighbourhoodChart extends Chart {
  private data: any[];

  constructor(config: Miami.ChartOptions) {
    super(config);
    this.defineScales();

    (<any>d3.promise)
      .csv(this.config.dataUrl)
      .then(data => this.data = data)
      .then(() => this.drawChart());

    this.container.from = this.container.width / 3 + 8;
    this.container.about = this.container.width / 3 * 2 + 28;

    this.controller.on(cst.event.F_LAYER_CHANGED, this.onLayerChange, this);
    this.controller.on(cst.event.TOTAL_STATE_UPDATE, this.onTotalStateUpdate, this);
  };

  private defineScales(): void {
    this.scales = {
      xFrom: d3.scale.linear().range([0, this.container.trueWidth / 3 - 40]),
      xAbout: d3.scale.linear().range([0, this.container.trueWidth / 3 - 40]),
      y: d3.scale.ordinal().rangeRoundBands([this.container.height, 0], .1)
    };

    this.axis.y = d3.svg.axis().scale(this.scales.y).orient('left').ticks(0);
  }

  private drawChart(): void {
    this.scales.xFrom.domain([0, d3.max(this.data, d => +d.from)]);
    this.scales.xAbout.domain([0, d3.max(this.data, d => +d.about)]);
    this.scales.y.domain(this.data.map(d => d[this.config.category]).reverse()).rangeRoundBands([this.container.height, 0], .55, 1.1);

    // from
    this.svg
      .selectAll('.' + this.config.category) // HACK replacing too mus
      .data(this.data)
      .enter()
      .append('rect')
      .attr('height', d => this.scales.y.rangeBand())
      .attr('x', 0)
      .attr('y', d => this.scales.y(d[this.config.category]))
      .attr('width', d => this.scales.xFrom(d.from) || 1)
      .attr('class', 'from-chart')
      .attr('transform', `translate(${this.container.from}, 0)`);

    // about
    this.svg
      .selectAll('.' + this.config.category)
      .data(this.data)
      .enter()
      .append('rect')
      .attr('height', d => this.scales.y.rangeBand())
      .attr('x', 0)
      .attr('y', d => this.scales.y(d[this.config.category]))
      .attr('width', d => this.scales.xAbout(d.about) || 1)
      .attr('class', 'about-chart')
      .attr('transform', `translate(${this.container.about}, 0)`);

    let self = this;
    const axis = this.svg
      .append('g')
      .call(this.axis.y);

    axis
      .selectAll('g')
      .classed('neighbourhood', true)
      .attr('data-name', d => { return d.replace(/ /g, '_'); })
      .on('click', function(d): void {
        self.controller.changeLayer([d3.select(this).attr('data-name')]);
      });

    axis
     .selectAll('g')
     .each(function() {
       const $g = d3.select(this);
       const text = $g.select('text').node() as any;
       const { width, height } = text.getBoundingClientRect();
       const paddingVetical = 8;
       const paddingHorizontal = 6;
       const rx = 4;
       $g
         .insert('rect', 'text')
         .attr({
           width: width + paddingVetical * 2,
           height: height + paddingHorizontal,
           rx,
           ry: rx,
           transform: `translate(${[0, -(height - paddingHorizontal)]})`,
         });
     })

    axis
      .selectAll('text')
      .style('text-anchor', 'start')
      .attr('transform', `translate(16, 0)`);

    this.svg
      .append('text')
      .attr('class', 'label')
      .attr('x', d => this.container.from)
      .attr('y', d => this.scales.y.range()[this.scales.y.range().length - 1] - 10)
      .text('Tweets From');

    this.svg
      .append('text')
      .attr('class', 'label')
      .attr('x', d => this.container.about)
      .attr('y', d => this.scales.y.range()[this.scales.y.range().length - 1] - 10)
      .text('Tweets About');

    this.svg
      .selectAll('.value.from')
      .data(this.data)
      .enter()
      .append('text')
      .attr('class', 'value')
      .attr('dominant-baseline', 'central')
      .text(d => d.from)
      .attr('x', d => this.scales.xFrom(d.from) + 5)
      .attr('y', d => this.scales.y(d[this.config.category]) + this.scales.y.rangeBand() / 2)
      .attr('transform', `translate(${this.container.from}, 0)`);

    this.svg
      .selectAll('.value.about')
      .data(this.data)
      .enter()
      .append('text')
      .attr('class', 'value about')
      .attr('dominant-baseline', 'central')
      .text(d => d.about)
      .attr('x', d => this.scales.xAbout(d.about) + 5)
      .attr('y', d => this.scales.y(d[this.config.category]) + this.scales.y.rangeBand() / 2)
      .attr('transform', `translate(${this.container.about}, 0)`);

    this.controller.updateComponentState('neighbourhoods', true);
  }

  private onLayerChange(state: Miami.State): void {
    let layers = _.get(state, `flatLayers.${state.section}`);

    this.svg
      .selectAll('.tick')
      .classed('active', function(): boolean {
        if (_.isEmpty(layers)) return false;
        return d3.select(this).attr('data-name') === layers[0];
      });
  }

  private onTotalStateUpdate(state: Miami.State): void {
    this.onLayerChange(state);
  }
}

export default NeighbourhoodChart;
