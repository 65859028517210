/// <reference path="../../typings/tsd.d.ts" />

namespace cst {
  /*
     F_ - flat map event
     V_ - volumetric map event
  */

  export const event = {
    COMPONENT_UPDATE: 'component_state_update',
    FULLY_INITIALIZED: 'fully-initialized',
    TOTAL_STATE_UPDATE: 'total_state_update',
    WINDOW_RESIZED: 'window-resized',
    SECTION_CHANGED: 'section_changed',
    RECALCULATE_SECTIONS: 'recalculate_sections',
    SCROLL_TO_SECTION: 'scroll_to_section',
    F_LAYER_CHANGED: 'flat_map_layer_changed',
    V_DATA_LOADED: 'volumetric_viz_data_loaded',
    V_DATASET_CHANGED: 'volumetric_map_dataset_changed',
    V_VISIBILITY_CHANGED: 'volumetric_map_visibility_changed',
    V_RANGE_CHANGED: 'volumetric_map_range_changed',
    V_HIGHLIGHT_CHANGED: 'volumetric_map_highlight_changed',
    V_EXPLORE_TOGGLED: 'toggle_explore',
    V_AREA_LENS_TOGGLED: 'volumetric_map_area_lens_toggled',
    V_AREA_LENS_MOVED: 'volumetric_map_area_lens_moved',
    V_SCREENSHOT_TAKEN: 'volumetric_map_screenshot_taken',
    V_PLAY: 'play',
    V_STOP: 'stop',
    V_CAMERA_TRANSITION_END: 'volumetric_map_camera_transition_ended',
    V_INIT_IDLE: 'volumetric_map_init_idle',
    V_TOGGLE_IDLE: 'volumetric_map_toggle_idle',
    MAP_ZOOM_IN: 'map_zoom_in',
    MAP_ZOOM_OUT: 'map_zoom_out',
    MAP_ZOOM_LIMIT: 'map_zoom_limit',
    MAP_MANUAL: 'map_manual_mode'
  };

  export const section = {
    LEAD: 'project',
    CHALLENGES: 'challenges',
    OBJECTIVE: 'objective',
    HISTORY: 'historical_heritage',
    CULTURE: 'cultural_diversity',
    BUSINESS: 'facing_pressure',
    SOURCES: 'sources',
    ZONES: 'closer_look',
    HEATMAP: 'sentiment_map',
    NEIGHBORHOODS: 'neighborhoods',
    SEMANTIC: 'semantic_connections',
    COMPETITORS: 'competitors',
    COMMUNITY: 'community',
    TARGET_AUDIENCE: 'target_audience',
    POTENTIAL_COMPETITORS: 'potential_competitors',
    SUMMARY: 'summary',
    EXPLORE: 'explore',
    CREDITS: 'credits'
  };

  export const sectionSettings: { [key: string]: { name: string, pageOffset: number } } = {};
    sectionSettings[cst.section.LEAD] = { name: cst.section.LEAD, pageOffset: null };
    sectionSettings[cst.section.CHALLENGES] = { name: cst.section.CHALLENGES, pageOffset: null };
    sectionSettings[cst.section.OBJECTIVE] = { name: cst.section.OBJECTIVE, pageOffset: null };
    sectionSettings[cst.section.HISTORY] = { name: cst.section.HISTORY, pageOffset: null };
    sectionSettings[cst.section.CULTURE] = { name: cst.section.CULTURE, pageOffset: null };
    sectionSettings[cst.section.BUSINESS] = { name: cst.section.BUSINESS, pageOffset: null };
    sectionSettings[cst.section.SOURCES] = { name: cst.section.SOURCES, pageOffset: null };
    sectionSettings[cst.section.ZONES] = { name: cst.section.ZONES, pageOffset: null };
    sectionSettings[cst.section.HEATMAP] = { name: cst.section.HEATMAP, pageOffset: null };
    sectionSettings[cst.section.NEIGHBORHOODS] = { name: cst.section.NEIGHBORHOODS, pageOffset: null };
    sectionSettings[cst.section.SEMANTIC] = { name: cst.section.SEMANTIC, pageOffset: null };
    sectionSettings[cst.section.COMPETITORS] = { name: cst.section.COMPETITORS, pageOffset: null };
    sectionSettings[cst.section.COMMUNITY] = { name: cst.section.COMMUNITY, pageOffset: null };
    sectionSettings[cst.section.TARGET_AUDIENCE] = { name: cst.section.TARGET_AUDIENCE, pageOffset: null };
    sectionSettings[cst.section.POTENTIAL_COMPETITORS] = { name: cst.section.POTENTIAL_COMPETITORS, pageOffset: null };
    sectionSettings[cst.section.SUMMARY] = { name: cst.section.SUMMARY, pageOffset: null };
    sectionSettings[cst.section.EXPLORE] = { name: cst.section.EXPLORE, pageOffset: null };
    sectionSettings[cst.section.CREDITS] = { name: cst.section.CREDITS, pageOffset: null };

  export const contentMarginsForSections: { [key: string]: { top: boolean, left: boolean } } = {};
    contentMarginsForSections[cst.section.LEAD] = { top: true, left: true };
    contentMarginsForSections[cst.section.CHALLENGES] = { top: true, left: true };
    contentMarginsForSections[cst.section.OBJECTIVE] = { top: true, left: true };
    contentMarginsForSections[cst.section.BUSINESS] = { top: true, left: true };
    contentMarginsForSections[cst.section.SOURCES] = { top: true, left: true };
    contentMarginsForSections[cst.section.CULTURE] = { top: true, left: true };
    contentMarginsForSections[cst.section.HISTORY] = { top: true, left: true };
    contentMarginsForSections[cst.section.ZONES] = { top: true, left: true };
    contentMarginsForSections[cst.section.HEATMAP] = { top: true, left: true };
    contentMarginsForSections[cst.section.NEIGHBORHOODS] = { top: true, left: true };
    contentMarginsForSections[cst.section.SEMANTIC] = { top: true, left: true };
    contentMarginsForSections[cst.section.COMPETITORS] = { top: true, left: true };
    contentMarginsForSections[cst.section.COMMUNITY] = { top: true, left: true };
    contentMarginsForSections[cst.section.TARGET_AUDIENCE] = { top: true, left: true };
    contentMarginsForSections[cst.section.POTENTIAL_COMPETITORS] = { top: true, left: true };
    contentMarginsForSections[cst.section.SUMMARY] = { top: true, left: true };
    contentMarginsForSections[cst.section.EXPLORE] = { top: true, left: false };
    contentMarginsForSections[cst.section.CREDITS] = { top: true, left: false };

  export const map = {
    FLAT: 'flat',
    VOLUMETRIC: 'volumetric'
  };

  export const sectionActiveMap: { [section: string]: string } = {};
    sectionActiveMap[cst.section.LEAD] = cst.map.FLAT;
    sectionActiveMap[cst.section.CHALLENGES] = cst.map.FLAT;
    sectionActiveMap[cst.section.OBJECTIVE] = cst.map.FLAT;
    sectionActiveMap[cst.section.BUSINESS] = cst.map.FLAT;
    sectionActiveMap[cst.section.SOURCES] = cst.map.FLAT;
    sectionActiveMap[cst.section.CULTURE] = cst.map.FLAT;
    sectionActiveMap[cst.section.HISTORY] = cst.map.FLAT;
    sectionActiveMap[cst.section.ZONES] = cst.map.FLAT;
    sectionActiveMap[cst.section.HEATMAP] = cst.map.FLAT;
    sectionActiveMap[cst.section.NEIGHBORHOODS] = cst.map.FLAT;
    sectionActiveMap[cst.section.SEMANTIC] = cst.map.VOLUMETRIC;
    sectionActiveMap[cst.section.COMPETITORS] = cst.map.VOLUMETRIC;
    sectionActiveMap[cst.section.COMMUNITY] = cst.map.VOLUMETRIC;
    sectionActiveMap[cst.section.TARGET_AUDIENCE] = cst.map.VOLUMETRIC;
    sectionActiveMap[cst.section.POTENTIAL_COMPETITORS] = cst.map.VOLUMETRIC;
    sectionActiveMap[cst.section.SUMMARY] = cst.map.VOLUMETRIC;
    sectionActiveMap[cst.section.EXPLORE] = cst.map.VOLUMETRIC;
    sectionActiveMap[cst.section.CREDITS] = cst.map.VOLUMETRIC;

  /*
     helps to highlight top menu buttons correctly and use it to change sections
   */

  export const sectionBlocks: { [sectionName: string]: string[] } = {};
    sectionBlocks[cst.section.CHALLENGES] = [
      cst.section.CHALLENGES,
      cst.section.OBJECTIVE,
      cst.section.HISTORY,
      cst.section.CULTURE,
      cst.section.BUSINESS,
      cst.section.SOURCES
    ];
    sectionBlocks[cst.section.ZONES] = [
      cst.section.ZONES,
      cst.section.HEATMAP,
      cst.section.NEIGHBORHOODS,
      cst.section.SEMANTIC,
      cst.section.COMPETITORS,
      cst.section.COMMUNITY
    ];
    sectionBlocks[cst.section.TARGET_AUDIENCE] = [
      cst.section.TARGET_AUDIENCE,
      cst.section.POTENTIAL_COMPETITORS,
      cst.section.SUMMARY
    ];
    sectionBlocks[cst.section.EXPLORE] = [cst.section.EXPLORE];
    sectionBlocks[cst.section.CREDITS] = [cst.section.CREDITS];

  export const sectionsInOrder = [
    cst.section.LEAD,
    cst.section.CHALLENGES,
    cst.section.OBJECTIVE,
    cst.section.HISTORY,
    cst.section.CULTURE,
    cst.section.BUSINESS,
    cst.section.SOURCES,
    cst.section.ZONES,
    cst.section.HEATMAP,
    cst.section.NEIGHBORHOODS,
    cst.section.SEMANTIC,
    cst.section.COMPETITORS,
    cst.section.COMMUNITY,
    cst.section.TARGET_AUDIENCE,
    cst.section.POTENTIAL_COMPETITORS,
    cst.section.SUMMARY,
    cst.section.EXPLORE,
    cst.section.CREDITS
  ];

  export const sectionsWithoutMapControls = [
    cst.section.LEAD,
    cst.section.CHALLENGES,
    cst.section.OBJECTIVE,
  ];

  export const sectionsWithoutMapControlsHighlight = [
    cst.section.EXPLORE,
    cst.section.CREDITS,
    cst.section.SUMMARY
  ];

  // XXX: this is the only way I made this work
  // EXHIBIT_TIMEOUT is being replaced by gulp-preprocessor with value or undefined
  // null is needed to fool the typescript compiler, 120000 is the default value
  const IDLE_TIMEOUT =
  // @echo EXHIBIT_TIMEOUT
  // @ifndef EXHIBIT_TIMEOUT
    null
  // @endif

  export const duration = {
    INSTANT: 0,
    SHORT_TRANSITION: 250,
    MEDIUM_TRANSITION: 500,
    LONG_TRANSITION: 750,
    EXTRA_LONG_TRANSITION: 1500,
    IDLE_TIMER: IDLE_TIMEOUT || 120000,
    MIN_SCROLL_TIME: 500
  };

  export const tweet_count = 3;
}

export default cst;
