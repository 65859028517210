/// <reference path="../../../typings/tsd.d.ts" />

import Controller from '../controller';
import cstv from './volumetric_viz_constants';
import * as _ from 'lodash';

abstract class VolumetricDataView {

  protected filesToLoad: number;

  protected meshes: { [setSubset: string]: THREE.Object3D[]; } = {};
  protected layers = { top: new THREE.Group(), bottom: new THREE.Group() };

  constructor(protected controller: Controller, protected meshGroup: THREE.Group, protected componentName: string) {
    this.meshGroup.add(this.layers.top);
    this.meshGroup.add(this.layers.bottom);
  }

  setDataset(setSubsetTop: string, setSubsetBottom: string): void {
    this.setDatasetForLayer('top', setSubsetTop);
    this.setDatasetForLayer('bottom', setSubsetBottom);
  }

  setVisibility(settings: Miami.TopBottom): void {
    this.layers.top.visible = settings.top;
    this.layers.bottom.visible = settings.bottom;
  }

  setRange(range: number[]): void {
    _.forOwn(this.meshes, setSubset => {
      setSubset.forEach((mesh: THREE.Mesh, i: number) => {
        mesh.visible = (i >= range[0] && i < range[1]) ? true : false;
      });
    });
  }

  protected setDatasetForLayer(layer: string, setSubset: string): void {
    /*remove*/
    let group = this.layers[layer];
    group.remove.apply(group, group.children);
    if (!setSubset) return;
    /*add*/
    group.add.apply(group, this.meshes[setSubset]);
  }

  protected onDataLoaded(): void {
    this.filesToLoad--;
    if (this.filesToLoad <= 0) {
      this.controller.updateComponentState(this.componentName, true);
    }
  }

}

export default VolumetricDataView;
