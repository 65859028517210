/// <reference path="../../../typings/tsd.d.ts" />

import cstv from './volumetric_viz_constants';
import utils from '../utils';
import * as _ from 'lodash';

interface MapOptions {
  geoCenter: Geo.LatLng;
  geoBounds: Geo.GeoBounds;
  zoomLevels: ZoomLevel[];
}

interface ZoomLevel {
  maxCameraOffset: number;
  value: number;
}

class TileMap {

  private totalTimesLoaded = 0; // for preventing tile loading from previous wave\
  private loader: THREE.TextureLoader;

  private geoCenter: Geo.LatLng;
  private geoBounds: Geo.GeoBounds;
  private zoomLevelOptions: ZoomLevel[];

  private zoomLevel: number = null;

  constructor(private sceneContainer: THREE.Group, options: MapOptions) {
    this.geoCenter = options.geoCenter || { long: -80.258694, lat: 25.749524 };
    this.geoBounds = options.geoBounds || { northWest: { lat: 26.749, long: -81.258 }, southEast: { lat: 24.749, long: -79.258 } };
    this.zoomLevelOptions = options.zoomLevels || [{ maxCameraOffset: Infinity, value: 11 }];
    this.loader = new THREE.TextureLoader();
    this.loader.crossOrigin = '';
  }

  update(cameraTarget: THREE.Vector3, cameraOffset: number): void {
    for (let z of this.zoomLevelOptions) {
      if (cameraOffset <= z.maxCameraOffset) {
        if (this.zoomLevel !== z.value) {
          this.zoomLevel = z.value;
          this.totalTimesLoaded++;
          this.clearMap();
          this.loadNewZoomLevel(this.zoomLevel, cameraTarget);
          break;
        } else return;
      }
    }
  }

  clearMap(): void {
    this.sceneContainer.remove.apply(this.sceneContainer, this.sceneContainer.children);
  }

  loadNewZoomLevel(zoomlvl: number, cameraTarget: THREE.Vector3): void {
    let timeLoading = this.totalTimesLoaded;
    /*calculate map parameters in tile numbers*/
    let centerTile = {
      x: utils.long2tile(this.geoCenter.long, zoomlvl),
      y: utils.lat2tile(this.geoCenter.lat, zoomlvl)
    };

    let tileBounds = {
      southEast: {
        x: utils.long2tile(this.geoBounds.southEast.long, zoomlvl).tileNum,
        y: utils.lat2tile(this.geoBounds.southEast.lat, zoomlvl).tileNum
      },
      northWest: {
        x: utils.long2tile(this.geoBounds.northWest.long, zoomlvl).tileNum,
        y: utils.lat2tile(this.geoBounds.northWest.lat, zoomlvl).tileNum
      }
    };

    /*calculate tile side in world coordinates*/
    let tileWidthLng = Math.abs(utils.tile2long(1, zoomlvl) - utils.tile2long(2, zoomlvl));
    let tileWidthWorld = tileWidthLng * 1000 * cstv.BASE_SCALE;
    let tileGeometry = new THREE.PlaneBufferGeometry(tileWidthWorld, tileWidthWorld);

    /*find tile under camera*/
    let targetTile = {
      x: centerTile.x.tileNum + cameraTarget.x / tileWidthWorld,
      y: centerTile.y.tileNum + cameraTarget.z / tileWidthWorld
    };

    /*get list of tiles to load*/
    let tilesToLoad = this.tilesInBounds(tileBounds);

    let maxDistanceFromTargetTile = 2; // creates effect of loading tiles from target tile
    while (tilesToLoad.length > 0) {
      for (let i = 0; i < tilesToLoad.length; i++) {
        let diffX = Math.abs(targetTile.x - tilesToLoad[i].x);
        let diffY = Math.abs(targetTile.y - tilesToLoad[i].y);
        if (diffX <= maxDistanceFromTargetTile && diffY <= maxDistanceFromTargetTile) {
          this.loadTile(tilesToLoad[i], zoomlvl, centerTile, tileGeometry, tileWidthWorld, timeLoading);
          tilesToLoad.splice(i, 1);
        }
      }
      maxDistanceFromTargetTile += 2;
    }
  }

  private loadTile(tile: Geo.Tile, zoomlvl: number, centerTile: Geo.TileDetailed,
  tileGeometry: THREE.PlaneBufferGeometry, tileWidthWorld: number, timeLoading: number): void {
    /*load tile from server*/
    let url = `https://api.tiles.mapbox.com/v4/mapbox.light/${zoomlvl}/${tile.x}/${tile.y}.png?access_token=${L.mapbox.accessToken}`;
    this.loader.load(url, (texture: THREE.Texture) => {
      if (this.totalTimesLoaded !== timeLoading) return;
      let tileMaterial = new THREE.MeshBasicMaterial({
        map: texture,
        blending: THREE.MultiplyBlending,
        transparent: true,
        side: THREE.DoubleSide
      });

      let mesh = new THREE.Mesh(tileGeometry, tileMaterial);
      mesh.matrixAutoUpdate = false;
      /*add to map*/
      this.sceneContainer.add(mesh);
      mesh.position.x = tileWidthWorld * ((tile.x - centerTile.x.tileNum) + 0.5 - centerTile.x.innerOffset);
      mesh.position.z = tileWidthWorld * ((tile.y - centerTile.y.tileNum) + 0.5 - centerTile.y.innerOffset);
      mesh.rotation.x = -Math.PI / 2;
      mesh.updateMatrix();
    });
  }

  private tilesInBounds(bounds: Geo.TileBounds): Geo.Tile[] {
    let currentX = bounds.northWest.x;
    let currentY = bounds.northWest.y;
    let tiles: Geo.Tile[] = [];
    while (currentY <= bounds.southEast.y) {
      while (currentX <= bounds.southEast.x) {
        tiles.push({ x: currentX, y: currentY });
        currentX++;
      }
      currentY++;
      currentX = bounds.northWest.x;
    }
    return _.shuffle(tiles);
  }

}

export default TileMap;
