/// <reference path="../../typings/tsd.d.ts" />

import * as _ from 'lodash';
import TimelineChart from './timeline_chart.ts';
import cst from './constants.ts';
import Controller from './controller.ts';
import utils from './utils';
import * as toggleRadio from './toggle_radio_buttons.ts';

class ExplorePanel {
  private timeline: TimelineChart;
  private $: any = {};
  private layerClasses: any = {
    'miraclemile.from': 'competitors',
    'miraclemile.about': 'community',
    'catchmentarea.about': 'potential-competitors',
    'catchmentarea.from': 'target-community'
  };

  constructor(el: string, private controller: Controller, chartOptions: Miami.ChartOptions) {
    this.timeline = new TimelineChart(chartOptions, this.controller);

    this.$.container = document.getElementById(el);
    this.$.datasetControls = this.$.container.getElementsByClassName('js-change-dataset');
    this.$.visibilityControls = this.$.container.getElementsByClassName('js-change-visibility');
    this.$.rangeControls = this.$.container.getElementsByClassName('js-change-range');
    this.$.areaLensControl = this.$.container.querySelector('.js-toggle-area-lens');
    this.$.miscControls = this.$.container.getElementsByClassName('js-screenshot');
    this.$.playButton = this.$.container.querySelector('.js-play');
    this.$.hideButton = document.getElementById('hide-controls');

    let self = this;
    for (let control of this.$.datasetControls) {
      control.addEventListener('click', function(): void {
        let newDataset: Miami.VolumetricData = {};

        let opposite = self.getOppositeActiveControl(this);
        if (opposite && utils.isSameDataset(this.dataset, opposite.dataset)) {
          opposite.checked = false;
          newDataset[opposite.name] = { set: null, subset: null };
        }

        if (toggleRadio.isToggleRadioButton(this) && !self.onlyLayer()) {
          toggleRadio.handleToggleRadioButtonGroup(this);
        }

        if (this.checked) {
          newDataset[this.name] = { set: this.dataset.set, subset: this.dataset.subset };
        } else {
          newDataset[this.name] = { set: null, subset: null };
        }

        self.controller.changeDataset(newDataset);
      });
    }

    for (let control of this.$.visibilityControls) {
      control.addEventListener('change', function(): void {
        let params = {};
        params[this.dataset.option] = _.zipObject([this.dataset.layer], [this.checked]);
        self.controller.changeVisibility(params);
      });
    }

    for (let control of this.$.rangeControls) {
      control.addEventListener('click', function(): void {
        let moveTo = self.timeline.slider.fixedExtent(this.dataset.option);
        self.timeline.slider.extent = moveTo;
        self.controller.changeRange(moveTo);
      });
    }

    for (let control of this.$.miscControls) {
      control.addEventListener('click', function(): void {
        self.controller.screenshot();
      });
    }

    this.$.areaLensControl.addEventListener('click', function(): void {
      let newStatus = !self.$.areaLensControl.classList.contains('active');
      self.controller.toggleAreaLens(newStatus);
    });

    this.$.playButton.addEventListener('click', () => {
      this.timeline.slider.isPlaying() ? this.controller.stop() : this.controller.play();
    });

    this.$.hideButton.addEventListener('click', () => {
      this.$.container.classList.toggle('down');
    });

    this.controller.on(cst.event.TOTAL_STATE_UPDATE, this.onTotalStateUpdate, this);
    this.controller.on(cst.event.V_EXPLORE_TOGGLED, this.onTotalStateUpdate, this);
    this.controller.on(cst.event.V_DATASET_CHANGED, this.onDatasetChange, this);
    this.controller.on(cst.event.V_RANGE_CHANGED, this.onRangeChange, this);
    this.controller.on(cst.event.V_VISIBILITY_CHANGED, this.onVisibilityChange, this);
    this.controller.on(cst.event.V_PLAY, this.onPlay, this);
    this.controller.on(cst.event.V_STOP, this.onStop, this);
    this.controller.on(cst.event.V_AREA_LENS_TOGGLED, this.onAreaLensToggle, this);
  }

  private onTotalStateUpdate(state: Miami.State): void {
    if (state.volExploreMode[state.section]) {
      let layers = this.layerOptions(state);
      this.checkButtons(state);
      this.onDatasetChange(state);
      this.timeline.slider.extent = state.volRange[state.section];
      this.onRangeChange(state);
      this.updateVisibilityButtonsBackground(layers);
      this.disableVisibilityControls(layers);
    }
  }

  private onDatasetChange(state: Miami.State): void {
    // HACK: Poor design. This function can be called not only on the Explore
    // but on some regular sections with optional datasets
    // which causes area lens to disappear
    // without this condition
    if (state.section != cst.section.EXPLORE) return;
    let layers = this.layerOptions(state);
    this.timeline.update(layers);
    this.updateDatasetControls();
    this.checkButtons(state);
    this.updateVisibilityButtonsBackground(layers);
    this.disableVisibilityControls(layers);
  }

  private onVisibilityChange(state: Miami.State): void {
    let layers = this.layerOptions(state);
    this.checkButtons(state);
    this.updateVisibilityButtonsBackground(layers);
    this.disableVisibilityControls(layers);
  }

 private getOppositeActiveControl(mycontrol: any): any {
    let oppositeName = mycontrol.name === 'top' ? 'bottom' : 'top';
    for (let control of this.$.datasetControls) {
      if (control.name !== oppositeName) continue;
      if (control.checked) return control;
    }
  }

  private onRangeChange(state: Miami.State): void {
    this.timeline.slider.moveBrush(state.volRange[state.section]);
  }

  private checkButtons(state: Miami.State): void {
    for (let control of this.$.datasetControls) {
      control.checked = utils.isSameDataset(control.dataset, state.volData[state.section][control.name]);
    }
    for (let control of this.$.visibilityControls) {
      control.checked = state.volVisibility[state.section][control.dataset.option][control.dataset.layer];
    }
  }

  private layerOptions(state: Miami.State): Miami.LayerOptions {
    let volData = state.volData[state.section];
    let top = utils.datasetName(<string>_.get(volData, 'top.set'), <string>_.get(volData, 'top.subset'));
    let bottom = utils.datasetName(<string>_.get(volData, 'bottom.set'), <string>_.get(volData, 'bottom.subset'));

    let layers: Miami.LayerOptions = {
      top: {name: top, classed: this.classed(top)},
      bottom: {name: bottom, classed: this.classed(bottom)}
    };

    return layers;
  }

  private updateDatasetControls(): void {
    let controls = d3.selectAll(this.$.datasetControls);
    let checked = controls.filter(':checked');
    if (checked.size() === 1) {
      checked.classed('last', true);
    } else {
      controls.classed('last', false);
    }
  }

  private updateVisibilityButtonsBackground(layers: Miami.LayerOptions): void {
    d3.selectAll('[name="top-visibility"] + .explore-btn').classed(layers.top.classed);
    d3.selectAll('[name="bottom-visibility"] + .explore-btn').classed(layers.bottom.classed);

    d3.select('.legend.top-layer').classed(layers.top.classed);
    d3.select('.legend.bottom-layer').classed(layers.bottom.classed);
  }

  private disableVisibilityControls(layers: Miami.LayerOptions): void {
    let inputs = d3.selectAll(this.$.visibilityControls);

    inputs
      .attr('disabled', function(): boolean {
        if (_.isNull(layers[this.dataset.layer].name)) return true;
      });

    let activeInputs = d3.selectAll('.js-change-visibility:not(:disabled):checked');
    activeInputs.attr('disabled', function (): boolean {
      if (activeInputs.filter(`[name=${this.name}]`).size() === 1) return true;
    });

    let disabled = activeInputs.filter('[data-option=connections]').size() === 0;
    if (disabled) this.controller.toggleAreaLens(false);
    this.$.areaLensControl.disabled = disabled;
  }

  private classed(currentLayer: string): any {
    let classes = _.map(_.values(this.layerClasses), e => e.toString());
    let values = _.map(classes, (c) => c === this.layerClasses[currentLayer]);
    return _.zipObject(classes, values);
  }

  private onPlay(): void {
    this.$.playButton.classList.add('active');
  }

  private onStop(): void {
    this.$.playButton.classList.remove('active');
  }

  private onAreaLensToggle(status: boolean): void {
    if (status === true) {
      this.$.areaLensControl.classList.add('active');
    } else {
      this.$.areaLensControl.classList.remove('active');
    }
  }

  private onlyLayer(): boolean {
    return d3.selectAll(this.$.datasetControls).filter(':checked').size() === 1;
  }
}

export default ExplorePanel;
