/// <reference path='../../typings/tsd.d.ts' />

import Chart from './chart.ts';
import Slider from './slider.ts';
import Controller from './controller';
import cst from './constants.ts';
import * as _ from 'lodash';

class TimelineChart extends Chart {
  slider: Slider;
  private data: any[];
  private range: Date[];
  private $axisNode: d3.Selection<SVGGElement>;
  private $axisTicks: d3.Selection<SVGGElement>;

  constructor(config: Miami.ChartOptions, protected controller: Controller) {
    super(config);
    this.container.paddings = { top: 2, bottom: 2 };
    this.container.maxBarHeight = (this.container.height / 2) - 15;

    this.load()
      .then(() => this.defineScales())
      .then(() => this.slider = new Slider(this.svg, this.container, this.scales, this.controller))
      .then(() => this.initialDraw());

    this.controller.on(cst.event.WINDOW_RESIZED, this.resize, this);
  }

  private defineScales(): void {
    this.scales = {
      x: d3.time.scale().range([0, this.container.width]).domain(this.range),
      yTop: d3.scale.linear().range([0, this.container.maxBarHeight]),
      yBottom: d3.scale.linear().range([0, this.container.maxBarHeight])
    };

    this.axis.x = d3.svg
      .axis()
      .scale(this.scales.x)
      .tickSize(10, 6)
      .tickFormat(d3.time.format('%b'));

    this.scales.xRangeBand = d3.scale
      .ordinal()
      .domain(<any>d3.time.month.range(this.range[0], this.range[1]))
      .rangeRoundBands(this.scales.x.range());
  }

  private load(): any {
    return (<any>d3.promise)
      .csv(this.config.dataUrl)
      .then(data => {
        this.data = data;
        for (let month of this.data) {
          let dateString = month.date.split('-');
          month.date = new Date(dateString[0], dateString[1] - 1, 1);
        }

        // HACK: one extra month for padding
        this.range = d3.extent(this.data, d => d.date);
        this.range[1] = d3.time.month.offset(_.last(this.range), 1);
      });
  }

  private initialDraw(): void {
    const self = this;

    this.svg.selectAll('.layer.top')
      .data(this.data)
      .enter()
      .append('rect')
      .attr('class', 'layer top')
      .attr('x', d => this.scales.x(d.date))
      .attr('y', this.container.height / 2)
      .attr('width', d => this.barWidth(d.date))
      .attr('height', 0);

    this.svg.selectAll('.layer.bottom')
      .data(this.data)
      .enter()
      .append('rect')
      .attr('class', 'layer bottom')
      .attr('x', d => this.scales.x(d.date))
      .attr('y', this.container.height / 2)
      .attr('width', d => this.barWidth(d.date))
      .attr('height', 0);

    this.slider.drawMask();

    this.svg.selectAll('.layer.top.outline')
      .data(this.data)
      .enter()
      .append('rect')
      .attr('class', 'layer top outline')
      .attr('x', d => this.scales.x(d.date))
      .attr('y', this.container.height / 2)
      .attr('width', d => this.barWidth(d.date))
      .attr('height', 0);

    this.svg.selectAll('.layer.bottom.outline')
      .data(this.data)
      .enter()
      .append('rect')
      .attr('class', 'layer bottom outline')
      .attr('x', d => this.scales.x(d.date))
      .attr('y', this.container.height / 2)
      .attr('width', d => this.barWidth(d.date))
      .attr('height', 0);

    this.$axisNode = this.svg
      .append('g')
      .attr('class', 'x axis');

    this.$axisNode
      .call(this.axis.x)
      .attr('transform', `translate(0, ${this.container.height / 2})`);

    this.$axisTicks = this.$axisNode.selectAll('.tick')
      .attr('transform', function(d): string {
        let transform = d3.transform(d3.select(this).attr('transform'));
        return `translate(${transform.translate[0]}, -5)`;
      });

    // XXX: Add year label in a hacky way
    this.$axisTicks.each(function() {
      const $this = d3.select(this);
      const month = $this.select('text').html();
      const $yearLabel = $this.append('text').classed('year-label', true)
      if (month === 'Mar') $yearLabel.text('2014');
      if (month === 'Jan') $yearLabel.text('2015');
    });

    this.$axisNode.selectAll('.tick text')
      .attr('transform', function() { return self.getTextTransform(this) });

    this.slider.appendBrush();

    this.controller.updateComponentState('explore', true);
  }

  update(layers: Miami.LayerOptions): void {
    let topData = this.filterData(layers.top.name);
    let bottomData = this.filterData(layers.bottom.name);

    this.updateDomain(this.scales.yTop, layers.top.name);
    this.updateDomain(this.scales.yBottom, layers.bottom.name);

    this.svg
      .selectAll('rect.layer.top')
      .data(topData)
      .classed(this.classedOrEmpty(layers.top.classed))
      .attr('x', d => this.scales.x(d.date))
      .attr('width', d => this.barWidth(d.date))
      .transition()
      .duration(300)
      .attr('y', d => (this.container.height / 2) - (this.scales.yTop(d.value) || 0))
      .attr('height', d => this.scales.yTop(d.value) || 0);

    this.svg
      .selectAll('rect.layer.bottom')
      .data(bottomData)
      .classed(this.classedOrEmpty(layers.bottom.classed))
      .attr('x', d => this.scales.x(d.date))
      .attr('width', d => this.barWidth(d.date))
      .transition()
      .duration(300)
      .attr('y', this.container.height / 2)
      .attr('height', d => this.scales.yBottom(d.value) || 0);

    this.svg
      .selectAll('rect.layer.top.outline')
      .data(topData)
      .attr('class', 'layer top outline')
      .attr('x', d => this.scales.x(d.date))
      .attr('width', d => this.barWidth(d.date))
      .transition()
      .duration(300)
      .attr('y', 15)
      .attr('height', d => this.container.maxBarHeight - (this.scales.yTop(d.value) || 0));

    this.svg
      .selectAll('rect.layer.bottom.outline')
      .data(bottomData)
      .attr('class', 'layer bottom outline')
      .attr('x', d => this.scales.x(d.date))
      .attr('width', d => this.barWidth(d.date))
      .transition()
      .duration(300)
      .attr('y', d => this.container.height / 2 + (this.scales.yBottom(d.value) || 0))
      .attr('height', d => this.container.maxBarHeight - (this.scales.yBottom(d.value) || 0));
  }

  private updateDomain(scale: any, category: string): void {
    if (category) scale.domain([0, d3.max(this.data, (d => +d[category]))]);
  }

  private filterData(column: string): any[] {
    return this.data.map(d => ({ date: d.date, value: d[column] }));
  }

  private resize(): void {
    const self = this;

    this.updateBounds();

    d3.select(this.svg.node().parentNode)
      .attr('width', this.container.trueWidth)
      .attr('height', this.container.trueHeight);

    this.scales.x.range([0, this.container.width]);
    this.container.maxBarHeight = (this.container.height / 2) - 15;
    this.scales.yTop.range([0, this.container.maxBarHeight]);
    this.scales.yBottom.range([0, this.container.maxBarHeight]);
    this.scales.xRangeBand.rangeRoundBands(this.scales.x.range());

    this.$axisNode
      .call(this.axis.x)
      .attr('transform', `translate(0, ${this.container.height / 2})`)

    this.$axisTicks
      .attr('transform', function(d): string {
        let transform = d3.transform(d3.select(this).attr('transform'));
        return `translate(${transform.translate[0]}, -5)`;
      })

    this.$axisNode
      .selectAll('.tick text')
      .attr('transform', function() { return self.getTextTransform(this) });

    this.svg.selectAll('rect.layer')
      .attr('x', d => this.scales.x(d.date))
      .attr('width', d => this.barWidth(d.date))

    this.slider.redraw();
  }

  private classedOrEmpty(classed: any): any {
    return _(classed).values().every(v => !v) ? {} : classed;
  }

  private barWidth(date: Date): number {
    let nextMonth = d3.time.month.offset(date, 1);
    return this.scales.x(nextMonth) - this.scales.x(date);
  }

  private getTextTransform(textNode: SVGTextElement): string {
    const center = this.scales.xRangeBand.rangeBand() / 2;
    const yearLabelShift = -11;
    if (textNode.classList.contains('year-label')) {
      return `translate(${center + yearLabelShift}, -13)`;
    } else {
      return `translate(${center}, 10)`;
    }
  }
}

export default TimelineChart;
