/// <reference path="../../../typings/tsd.d.ts" />

import { fill, chunk, values, flattenDeep, flatten, zip } from 'lodash';
import cst from '../constants';
import cstv from './volumetric_viz_constants';
import utls from '../utils';
import Controller from '../controller';

/*
 * array([]) of chunks ([]), with positions data([])
 * array: [
 * chunk: [ positions: [](1), another meta: [] ...]
 * ]
 */
type ConnectionsData = number[][][];

const EXAMPLES_PATH = './models/example';
const GRADIENT = [0.0, 0.0, 0.05, 0.10, 0.15, 0.214293, 0.268672, 0.318312, 0.365231, 0.410503, 0.454824, 0.498715, 0.542629, 0.587024, 0.63243, 0.67956, 0.729533, 0.784475, 0.85, 0.9, 0.95, 1.0, 1.0];

class ExampleScheme extends THREE.Group {

  // XXX: 4 blobs, 12 connections
  private filesToLoad = 4 + 12;
  private controller: Controller;
  private componentName: string;
  private loader = new THREE.PLYLoader();

  constructor(name: string, controller: Controller) {
    super();
    this.controller = controller;
    this.componentName = name;
    values<string>(cstv.sets).forEach(s => {
      values<string>(cstv.subsets).forEach(ss => {

        const setSubset = utls.datasetName(s, ss);

        this.loadBlobsGeometry(s, ss).then((geometry: THREE.BufferGeometry) => {
          const color = cstv.colorsForBlobs[setSubset].light.getHex();
          this.add(this.getBlobs(setSubset, geometry, color));
        });

        this.loadConnectionsData(s, ss).then((data: ConnectionsData[]) => {
          const { start, end } = cstv.colorsForConnections[setSubset];
          const geometry = this.makeConnectionGeometry(data, start, end);
          this.add(this.getConnections(geometry, setSubset));
        });

      });
    });

    this.controller.on(cst.event.TOTAL_STATE_UPDATE, this.onTotalStateUpdate);
  }

  highlightDataset = (set: string, subset: string) => {
    const setSubset = utls.datasetName(set, subset);
    this.children.forEach((child: THREE.LineSegments|THREE.Mesh) => {
      child.material.opacity = !setSubset || child.name === setSubset ? 1.0 : 0.3;
    });
  }

  private onTotalStateUpdate = () => {
    this.highlightDataset(cstv.sets.MIRACLE, cstv.subsets.ABOUT);
    this.controller.on(cst.event.V_HIGHLIGHT_CHANGED, this.highlightDataset);
  }

  private loadBlobsGeometry = (set: string, subset: string): Promise<THREE.BufferGeometry> => {
    const path = `${EXAMPLES_PATH}/blobs-exmpl-${set}-${subset}-.ply`;
    return new Promise(resolve => {
      this.loader.load(path, (geometry: THREE.Geometry) => {
        this.onDataLoaded();
        resolve(new THREE.BufferGeometry().fromGeometry(geometry));
      });
    })
  }

  private getBlobs = (setSubset: string, geometry: THREE.BufferGeometry, color: number): THREE.Mesh => {
    const material = new THREE.MeshBasicMaterial({ color, side: THREE.DoubleSide, transparent: true });
    const mesh = new THREE.Mesh(geometry, material);
    mesh.name = setSubset;
    mesh.rotation.set(-Math.PI / 2, 0, 0);
    mesh.position.set(0, 0.1, 0);
    mesh.scale.set(cstv.BASE_SCALE, cstv.BASE_SCALE, cstv.BASE_SCALE);
    return mesh;
  }

  private getConnections = (geometry: THREE.BufferGeometry, setSubset: string): THREE.LineSegments => {
    const material = new THREE.LineBasicMaterial({ vertexColors: THREE.VertexColors, transparent: true });
    const segments = new THREE.LineSegments(geometry, material);
    segments.name = setSubset;
    segments.rotation.set(-Math.PI / 2, 0, 0);
    segments.position.set(0, 0.1, 0);
    segments.scale.set(cstv.BASE_SCALE, cstv.BASE_SCALE, cstv.BASE_SCALE);
    return segments;
  }

  private loadConnectionsData = (set: string, subset: string): Promise<ConnectionsData[]> => {
    return Promise.all(cstv.CONNECTION_PARTS.map(part =>
      this.loadConnectionsPart(set, subset, part)
    ));
  }

  private loadConnectionsPart = (set: string, subset: string, part: string): Promise<ConnectionsData> => {
    const path = `${EXAMPLES_PATH}/connections-exmpl-${set}-${subset}-${part}.json`;
    return new Promise(resolve => {
      d3.json(path).get((error: any, data: ConnectionsData) => {
        this.onDataLoaded();
        resolve(data);
      });
    })
  }

  private makeConnectionGeometry = (data: ConnectionsData[], startColor: THREE.Color, endColor: THREE.Color): THREE.BufferGeometry => {
    const geometry: THREE.BufferGeometry = new THREE.BufferGeometry();
    const connectionsAmount = data[0].length;
    geometry.addAttribute('position', this.connectionPositionsBuffer(data));
    geometry.addAttribute('color', this.connectionsColorsBuffer(connectionsAmount,startColor, endColor));
    return geometry;
  }

  private connectionPositionsBuffer = (data: ConnectionsData[]): THREE.BufferAttribute => {
    const fullConnections = zip.apply(null, data);
    const segmentedConnections = fullConnections.map(connection => {
      const flatConnection = flattenDeep(connection);
      const points = chunk(flatConnection, 3);
      const segmentedPoints = this.segmentize(points);
      const segmentedConnection = flatten<number>(segmentedPoints);
      return segmentedConnection;
    });
    const positions = flatten<number>(segmentedConnections);
    return new THREE.BufferAttribute(new Float32Array(positions), 3);
  }

  private connectionsColorsBuffer = (connectionsAmount: number, startColor: THREE.Color, endColor: THREE.Color): THREE.BufferAttribute => {
    const colorGradient = GRADIENT.map(val =>
      startColor.clone()
        .lerp(endColor.clone(), val)
        .toArray()
    );
    const colorsForConnection = flatten<number>(this.segmentize(colorGradient));
    const colors = flatten<number>(fill<number>(Array(connectionsAmount), colorsForConnection));
    return new THREE.BufferAttribute(new Float32Array(colors), 3);
  }

  private segmentize = (points: any[][]) => {
    return points.map((point: [any, any, any], i: any) => {
      const notFirst = i !== 0;
      const notLast = i !== points.length - 1;
      return notFirst && notLast ? point.concat(point) : point;
    });
  }

  private onDataLoaded(): void {
    this.filesToLoad--;
    if (this.filesToLoad <= 0) {
      this.controller.updateComponentState(this.componentName, true);
    }
  }
}


export default ExampleScheme;

