import Controller from './controller';
import cst from './constants';

/**
 * Creates a function that tolerates a number of calls before actualy executing
 * After every execution counter becomes 0 again
 *
 * @param {number} Number of calls function tolerates before being executed
 * @param {Function} Original function
 * @returns {Function} New function
 */
const tolerant = <T extends Function>(n: number, f: T): T => {
  let callCount = 0;
  const tolerantFunction: T = function() {
    if (callCount <= n) callCount++;
    else {
      callCount = 0;
      f.apply(this, arguments);
    }
  } as any;
  return tolerantFunction;
}

class IdleModeManager {

  private timeout: number;
  private isIdle: boolean;

  constructor(private container: HTMLElement, private controller: Controller) {
    this.controller.on(cst.event.V_INIT_IDLE, this.init)
    this.controller.on(cst.event.V_TOGGLE_IDLE, this.onToggle);
    this.controller.updateComponentState('idleManager', true);
  }

  private init = (state: Miami.State) => {
    this.isIdle = state.idleMode;
    this.container.addEventListener('click', this.resetTimer);
    this.container.addEventListener('wheel', this.resetTimer);
    // FIXME: in some transitions between sections, mousemove fires without
    // actually moving mouse.
    this.container.addEventListener('mousemove', tolerant(10, this.resetTimer).bind(this));
    this.container.addEventListener('touchstart', this.resetTimer);
    this.container.addEventListener('touchmove', this.resetTimer);
    this.container.addEventListener('keydown', this.resetTimer);
    this.resetTimer();
  }

  private resetTimer = () => {
    if (this.isIdle) this.controller.toggleIdleMode(false);
    else clearTimeout(this.timeout);
    this.timeout = setTimeout(this.startIdle, cst.duration.IDLE_TIMER);
  }

  private startIdle = () => {
    this.controller.changeSection(cst.section.EXPLORE);
    this.controller.toggleExplore(true);
    this.controller.toggleIdleMode(true);
  }

  private onToggle = (state: Miami.State) => this.isIdle = state.idleMode;
}

export default IdleModeManager;
