/// <reference path="../../typings/tsd.d.ts" />

class Tooltip {

  alreadyShown = false;
  private $tooltip: d3.Selection<HTMLDivElement>;
  private $rightSide: d3.Selection<HTMLDivElement>;
  private $leftSide: d3.Selection<HTMLDivElement>;

  constructor(container: HTMLElement, text: string, imageUrl?: string) {
    this.$tooltip = d3.select(container)
      .append('div')
      .classed('tooltip', true)
      .on('click', () => this.toggle(false));

    this.$leftSide = this.$tooltip.append('div')
      .classed('tooltip-left', true)
      .style('background', `url(${imageUrl}) center center no-repeat`);

    this.$rightSide = this.$tooltip.append('div')
      .classed('tooltip-right', true);

    this.$rightSide.append('p')
      .text(text);

    this.$rightSide.append('button')
      .classed('btn got-it', true)
      .text('Got it');
  }

  toggle = (visible: boolean) => {
    if (visible) {
      if (this.alreadyShown) return;
      else this.alreadyShown = true;
    }
    this.$tooltip.classed('is-visible', visible);
  }
}

export default Tooltip;
