/// <reference path="../../typings/tsd.d.ts" />

// Promise polyfill (added for IE11)
declare var require: Function;
window['Promise'] = window['Promise'] || require('es6-promise').Promise;
// External svg references polyfill (added for IE11)
require('svgxuse');

import cst from './constants';
import cstf from './flat_map_constants';
import cstv from './volumetric_map/volumetric_viz_constants';
import Controller from './controller';
import Layout from './layout';
import IdleModeManager from './IdleModeManager';
import Zones from './storyline/zones';
import Neighborhoods from './storyline/neighborhoods';
import FlatMap from './flat_map';
import VolumetricMap from './volumetric_map/volumetric_viz';
import MapManager from './MapManager';
import ExplorePanel from './explore_panel';
import * as _ from 'lodash';

const appContainer = document.body;

// Prevent pinch zoom
appContainer.addEventListener('touchmove', (e: TouchEvent) => {
  if (e.touches.length >= 2) e.preventDefault();
});

let idleModeEnabled = false;
// @ifdef EXHIBIT
idleModeEnabled = true;
// @endif

// Disable right click menu everywhere in exhibition mode
// @ifdef EXHIBIT
appContainer.oncontextmenu = () => false;
// @endif

let components = {
  volumetricMapBlobs: false,
  volumetricMapConnections: false,
  volumetricExampleScheme: false,
  zones: false,
  neighbourhoods: false,
  explore: false,
  idleManager: false
};

let controller = new Controller({
  section: cst.section.LEAD,
  flatLayers: _.cloneDeep(cstf.defaultLayers),
  volData: _.cloneDeep(cstv.defaultDataForSections),
  volRange: _.cloneDeep(cstv.defaultRangesForSections),
  volVisibility: _.cloneDeep(cstv.defaultVisibilityForSections),
  volExploreMode: _.cloneDeep(cstv.defaultExploreModeForSections),
  idleModeEnabled,
  idleMode: false
}, components);

new Layout(appContainer, controller);
new IdleModeManager(appContainer, controller);
new Zones('#activity-zoning', controller);
new Neighborhoods('#neighborhoods', controller);
let flatMap = new FlatMap('#flat-map', controller);
let volumetricMap = new VolumetricMap('#volumetric-map', controller);
new MapManager(controller, flatMap, volumetricMap);

new ExplorePanel('explore-panel', controller, {
  el: d3.select('#timeline'),
  dataUrl: 'data/map_3d/histogram-count.csv',
  margins: {left: 10, right: 10, top: 1, bottom: 1}
});
