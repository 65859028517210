/// <reference path="../../../typings/tsd.d.ts" />

import { fill } from 'lodash';
import cst from '../constants';
import cstv from './volumetric_viz_constants';
import Controller from '../controller';
import Tooltip from '../tooltip';
import utils from '../utils';

class AreaLens {

  isActive = false;
  radius = 3;
  width = 0.3;
  currentPosition: THREE.Vector3;

  private ring: THREE.Mesh;
  private raycaster = new THREE.Raycaster();
  private tooltip: Tooltip;

  private boundOnPointerMove: EventListener;
  private boundOnPointerEnd: EventListener;
  private boundOnHover: EventListener;

  private _highlightTimeout: number;

  private isHighlighting: boolean;

  private isPulsating = false;
  private pulsationTempo = 2000;

  constructor(private controller: Controller, private scene: THREE.Scene,
              private camera: THREE.Camera, private plane: THREE.Plane,
              private container: HTMLElement) {

    this.boundOnPointerMove = this.onPointerMove.bind(this);
    this.boundOnPointerEnd = this.onPointerEnd.bind(this);
    this.boundOnHover = this.onHover.bind(this);
    this.createRing();
    this.tooltip = new Tooltip(
      container,
      'Move "Area lens" around to select connections',
      './images/lens-prompt-illustration@1x.png'
    );
  }

  toggle(status: boolean): void {
    this.isActive = status;
    this.ring.visible = status;
    this.togglePulsation(status);
    this.tooltip.toggle(status);
    if (status === true) {
      this.container.addEventListener('mousemove', this.boundOnHover);
    } else {
      this.container.removeEventListener('mousemove', this.boundOnHover);
    }
  }

  changeColor(setSubsetTop: string, setSubsetBottom: string): void {
    let topColor = setSubsetTop ? cstv.colorsForConnections[setSubsetTop].ring : null;
    let bottomColor = setSubsetBottom ? cstv.colorsForConnections[setSubsetBottom].ring : null;
    let finalColor: THREE.Color;
    if (!topColor || !bottomColor) {
      finalColor = topColor || bottomColor;
    } else if (topColor.getHex() === bottomColor.getHex()) {
      finalColor = topColor;
    } else {
      finalColor = cstv.UNDEFINED_AREA_LENS_COLOR;
    }
    let ringMaterial: THREE.MeshBasicMaterial = <THREE.MeshBasicMaterial>this.ring.material;
    ringMaterial.color = finalColor;
  }

  takeCorrectSide(): void {
    let cameraDirection = this.camera.position.y >= 0 ? 1 : -1;
    this.ring.position.y = 0.05 * cameraDirection;
  }

  onPointerStart(): void {
    this.container.addEventListener('touchmove', this.boundOnPointerMove);
    this.container.addEventListener('touchend', this.boundOnPointerEnd);
    this.container.addEventListener('mousemove', this.boundOnPointerMove);
    this.container.addEventListener('mouseup', this.boundOnPointerEnd);
    // XXX: Special behaviour for mouse, touch is not affected
    this.container.removeEventListener('mousemove', this.boundOnHover);
  }

  showNewHighlight(): void {
    let n = Math.ceil(Math.random() * (cstv.highlights.length - 3));
    let randomHighlight = cstv.highlights[n];
    cstv.highlights.push(cstv.highlights.splice(n,1)[0]);

    d3.transition()
      .duration(cst.duration.EXTRA_LONG_TRANSITION + cst.duration.EXTRA_LONG_TRANSITION * Math.random())
      .ease('cubic-in-out')
      .tween('areaLens', () => {
        let intepolator: any = d3.interpolateObject(this.ring.position as any, randomHighlight as any); // HACK: ugly typecasting
        return t => this.moveAreaLens(intepolator(t));
      }).each('end', () => {
        if (!this.isHighlighting) return;
        this._highlightTimeout = window.setTimeout( this.showNewHighlight.bind(this), cst.duration.EXTRA_LONG_TRANSITION * ( Math.random() + 2 ) );
      });
  }

  toggleHighlightsMode(enabled: boolean): void {
    this.isHighlighting = enabled;
    this.togglePulsation(!enabled);
    this.tooltip.toggle(!enabled);
    if (enabled) this.showNewHighlight();
    else clearTimeout(this._highlightTimeout);
  }

  private onPointerMove(e: MouseEvent|TouchEvent): void {
    e.preventDefault();
    let pointerX, pointerY;
    if (typeof TouchEvent !== 'undefined' && e instanceof TouchEvent) {
      if (e.touches.length !== 1) return;
      pointerX = e.touches[0].clientX;
      pointerY = e.touches[0].clientY;
    } else if (e instanceof MouseEvent) {
      pointerX = e.clientX;
      pointerY = e.clientY;
    }

    let pointer = utils.toNormalizedDeviceCoordinates(pointerX, pointerY);
    this.raycaster.setFromCamera(pointer, this.camera);

    let intersection = this.raycaster.ray.intersectPlane(this.plane);
    this.moveAreaLens(intersection);
    this.togglePulsation(false);
    this.container.classList.toggle('area-lens-hovered', true);
  }

  private onPointerEnd(): void {
    this.togglePulsation(true);
    this.container.removeEventListener('touchmove', this.boundOnPointerMove);
    this.container.removeEventListener('touchend', this.boundOnPointerEnd);
    this.container.removeEventListener('mousemove', this.boundOnPointerMove);
    this.container.removeEventListener('mouseup', this.boundOnPointerEnd);
    this.container.addEventListener('mousemove', this.boundOnHover);
    this.container.classList.toggle('area-lens-hovered', false);
  }

  private onHover(e: MouseEvent): void {
    let mouse = utils.toNormalizedDeviceCoordinates(e.clientX, e.clientY);
    this.raycaster.setFromCamera(mouse, this.camera);
    let intersection = this.raycaster.ray.intersectPlane(this.plane);
    if (!intersection) return;
    let isHit = new THREE.Vector3().subVectors(intersection, this.currentPosition).length() <= this.radius;
    this.togglePulsation(!isHit);
    this.container.classList.toggle('area-lens-hovered', isHit);
  }

  private moveAreaLens(target: THREE.Vector3): void {
    let cameraDirection = this.camera.position.y >= 0 ? 1 : -1
    this.ring.position.copy(target).add(new THREE.Vector3(0, 0.05 * cameraDirection, 0));
    this.currentPosition = this.ring.position.clone();
    this.tooltip.toggle(false);
    this.controller.moveAreaLens(target);
  }

  private createRing(): void {
    let geometry = new THREE.RingGeometry(this.radius - this.width, this.radius, 30, 0, 0, 6.3);
    let material = new THREE.MeshBasicMaterial({
      color: 0xff0000,
      side: THREE.DoubleSide
    });
    this.ring = new THREE.Mesh(geometry, material);
    this.scene.add(this.ring);
    this.ring.position.set(0, 0.05, 0);
    this.ring.rotation.set(-Math.PI / 2, 0, 0);
    this.ring.visible = false;
    this.currentPosition = this.ring.position.clone();
  }

  private togglePulsation(status: boolean) {
    if (status === this.isPulsating) return;
    this.isPulsating = status;
    if (status === true) d3.timer(this.pulsate as any);
  }

  private pulsate = (t: number) => {
    if (!this.isPulsating) {
      this.ring.scale.set(1, 1, 1);
      return true;
    } else {
      const tCycled = t % this.pulsationTempo * 2;
      const tBounced = tCycled < this.pulsationTempo
        ? tCycled
        : this.pulsationTempo * 2 - tCycled;
      const tNorm = tBounced / this.pulsationTempo;
      const scale = fill(Array(3), 1 + tNorm / 3) as number[];
      this.ring.scale.fromArray(scale);
    }
  }

}

export default AreaLens;
