/// <reference path="../../../typings/tsd.d.ts" />

import * as _ from 'lodash';
import cstv from './volumetric_viz_constants';
import utils from '../utils';
import VolumetricMap from './volumetric_viz';
import Controller from '../controller';
import VolumetricDataView from './VolumetricDataView';

class VolumetricBlobs extends VolumetricDataView {

  private colors = { top: new THREE.Color(), bottom: new THREE.Color() };

  constructor(controller: Controller, meshGroup: THREE.Group, componentName: string) {
    super(controller, meshGroup, componentName);
    this.loadData();
  }

  setRange(range: number[]): void {
    super.setRange(range);
    _.forOwn(this.layers, (meshes: THREE.Mesh[], layer: string) => {
      this.updateColors(layer);
      this.updatePositions(layer);
    });
  }

  protected setDatasetForLayer(layer: string, setSubset: string): void {
    super.setDatasetForLayer(layer, setSubset);
    this.colors[layer] = cstv.colorsForBlobs[setSubset];
    this.updateColors(layer);
    this.updatePositions(layer);
  }

  private updatePositions(layer: string): void {
    let layerDirection = layer === 'top' ? 1 : -1;
    let visibleCount = 1;
    let visibleAmount = this.layers[layer].children.filter((mesh) => { return mesh.visible; }).length;
    this.layers[layer].children.forEach((mesh: THREE.Mesh, i: number) => {
      if (!mesh.visible) return;
      mesh.position.y = visibleCount * cstv.SLICE_STEP * layerDirection;
      mesh.updateMatrix();
      visibleCount++;
    });
  }

  private updateColors(layer: string): void {
    var visibleCount = 0;
    let visibleAmount = this.layers[layer].children.filter((mesh) => { return mesh.visible; }).length;
    _.forEachRight(this.layers[layer].children, (mesh: THREE.Mesh, i: number) => {
      if (!mesh.visible) return;
      let material = <THREE.MeshBasicMaterial>mesh.material;
      material.color = this.colors[layer].light
        .clone()
        .lerp(this.colors[layer].shade.clone(), visibleCount / visibleAmount);
      visibleCount++;
    });
  }

  private loadData(): void {
    let sets = Object.keys(cstv.sets);
    let subsets = Object.keys(cstv.subsets);
    this.filesToLoad = sets.length * subsets.length * cstv.SLICE_COUNT;

    var loader = new THREE.PLYLoader();
    sets.forEach(set => {
      subsets.forEach(subset => {

        for (var sliceIndex = 0; sliceIndex < cstv.SLICE_COUNT; sliceIndex++) {
          ((sliceIndex) => {
            loader.load(
              '/models/blobs/blobs-' + cstv.sets[set] + '-' + cstv.subsets[subset] + '-' + (sliceIndex < 10 ? '0' + sliceIndex : sliceIndex) + '.ply',
              (geometry) => {
                this.createMeshes(cstv.sets[set], cstv.subsets[subset], sliceIndex, geometry);
              }
            );
          })(sliceIndex);
        }

      });
    });
  }

  private createMeshes(set: string, subset: string, sliceIndex: number, geometry: THREE.Geometry): void {
    var setSubset = utils.datasetName(set, subset);
    var material = new THREE.MeshBasicMaterial({ side: THREE.DoubleSide, color: 0x999999 });

    var bufferGeometry = new THREE.BufferGeometry().fromGeometry(geometry);
    var mesh = new THREE.Mesh(bufferGeometry, material);
        mesh.rotation.set(-Math.PI / 2, 0, 0);
        mesh.scale.set(cstv.BASE_SCALE, cstv.BASE_SCALE, cstv.BASE_SCALE);
        mesh.matrixAutoUpdate = false;

    if (!(setSubset in this.meshes)) this.meshes[setSubset] = [];

    this.meshes[setSubset][sliceIndex] = mesh;
    this.onDataLoaded();
  }

}

export default VolumetricBlobs;
