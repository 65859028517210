/// <reference path="../../../typings/tsd.d.ts" />

import * as _ from 'lodash';
import cstv from './volumetric_viz_constants';
import utils from '../utils';
import VolumetricMap from './volumetric_viz';
import Controller from '../controller';
import VolumetricDataView from './VolumetricDataView';

export const GRADIENTS: number[][] = [
  [0.0, 0.0, 0.05, 0.10],
  [0.15, 0.214293, 0.268672, 0.318312, 0.365231, 0.410503, 0.454824, 0.498715, 0.542629, 0.587024, 0.63243, 0.67956, 0.729533, 0.784475, 0.85],
  [0.9, 0.95, 1.0, 1.0]
];

const VERTEX_SHADER: string = `
  uniform float EPSILON;
  ${THREE.ShaderChunk.logdepthbuf_pars_vertex}
  attribute vec3 color;
  attribute vec3 ground;
  varying vec3 col;
  varying vec3 grnd;
  void main() {
    col = color;
    grnd = ground;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    ${THREE.ShaderChunk.logdepthbuf_vertex}
  }
`;

const FRAGMENT_SHADER: string = `
  ${THREE.ShaderChunk.logdepthbuf_pars_fragment}
  uniform int areaLensOn;
  uniform vec3 pointer;
  varying vec3 col;
  varying vec3 grnd;
  void main() {
    vec4 rgba = vec4(col, 1.0);
    if (areaLensOn == 1) {
      if (length(grnd - pointer) > 2.75) {
        discard;
      }
    }
    gl_FragColor = rgba;
    ${THREE.ShaderChunk.logdepthbuf_fragment}
  }
`;

class VolumerticMiamiConnections extends VolumetricDataView {

  private shader: THREE.ShaderMaterial;

  constructor(controller: Controller, meshGroup: THREE.Group, componentName: string) {
    super(controller, meshGroup, componentName);
    this.shader = new THREE.ShaderMaterial({
      uniforms: {
        EPSILON: { type: 'f', value: cstv.EPSILON },
        areaLensOn: { type: 'i', value: 0 },
        pointer: { type: 'v3', value: [0, 0, 0] }
      },
      vertexShader: VERTEX_SHADER,
      fragmentShader: FRAGMENT_SHADER
    });

    this.loadData();
  }

  setRange(range: number[]): void {
    super.setRange(range);
    _.forOwn(this.layers, (meshes: THREE.Mesh[], layer: string) => {
      this.updatePositions(layer);
    });
  }

  onAreaLensToggle(status: boolean): void {
    this.shader.uniforms.areaLensOn.value = status ? 1 : 0;
  }

  onAreaLensMove(position: THREE.Vector3): void {
    this.shader.uniforms.pointer.value = position;
  }

  protected setDatasetForLayer(layer: string, setSubset: string): void {
    super.setDatasetForLayer(layer, setSubset);
    this.updatePositions(layer);
  }

  private updatePositions(layer: string): void {
    let layerDirection = layer === 'top' ? 1 : -1;
    let visibleAmount = this.layers[layer].children.filter((mesh) => { return mesh.visible; }).length;
    let groundLevel = visibleAmount * cstv.SLICE_STEP * layerDirection;
    this.layers[layer].children.forEach((mesh: THREE.Mesh, i: number) => {
      if (!mesh.visible) return;
      mesh.scale.y = layerDirection;
      mesh.position.y = groundLevel;
      mesh.updateMatrix();
    });
  }

  private loadData(): void {
    let sets = Object.keys(cstv.sets);
    let subsets = Object.keys(cstv.subsets);
    this.filesToLoad = sets.length * subsets.length;

    /**
     * TODO: This algorithm is a mess. Component ExampleScheme has a better one.
     * If you need to make some changes here, delete everything and reuse the code
     * in ExampleScheme
     */

    sets.forEach(set => {
      subsets.forEach(subset => {

        var dataChunks: any[] = [];
        var leftToLoadInChunk: number = cstv.CONNECTION_PARTS.length;

        var setSubset: string = utils.datasetName(cstv.sets[set], cstv.subsets[subset]);
        this.meshes[setSubset] = [];
        for (let i: number = 0; i < cstv.SLICE_COUNT; i++) {
          this.meshes[setSubset].push(null);
        }

        for (let i: number = 0; i < leftToLoadInChunk; i++) {
          var path: string = '/models/connections/connections-' + cstv.sets[set] + '-' + cstv.subsets[subset] + '-' + cstv.CONNECTION_PARTS[i] + '.json';
          ((i: number) => {
            d3.json(path).get((error: Error, data: any) => {
              dataChunks[i] = data;
              leftToLoadInChunk--;
              if (leftToLoadInChunk === 0)
                this.createMeshes(setSubset, dataChunks);
            });
          })(i);
        }

      });
    });
  }

  private createMeshes(setSubset: string, data: any[]): void {
    let positionsForLayers: number[][] = [];
    let colorsForLayers: number[][] = [];
    let groundPointsForLayers: number[][] = [];

    /*configure arrays of attribute data*/
    data.forEach((chunk: number[][][], chunkIndex: number) => {
    /*
      fragment - start, middle or end of connection
      each chunk has one type of fragments
      every fragment consists of line segments
      each line consists of two vertices (vector3)
    */
      let gradient: number[] = GRADIENTS[chunkIndex];
      for (let i: number = 0; i < chunk.length; i++) {

        let fragment: number[][] = chunk[i];
        let positionsData: number[] = fragment[0];
        let layerIndexes: number[] = fragment[1];
        var groundPoint: number[] = fragment[2];
        let segmentsAmount: number = positionsData.length / 3 - 1;

        for (var j: number = 0; j < segmentsAmount; j++) {
          // a, b - vertices of a line segment
          var aIndex: number = j * 3;
          var bIndex: number = (j + 1) * 3;
          var positions: number[] = [];
          var colors: number[] = [];
          var groundPoints: number[] = [];

          /*add position data*/
          positions[0] = positionsData[aIndex] * cstv.BASE_SCALE; // x
          positions[2] = -(positionsData[aIndex + 1] * cstv.BASE_SCALE); // z
          positions[1] = positionsData[aIndex + 2] * cstv.BASE_SCALE; // y

          positions[3] = positionsData[bIndex] * cstv.BASE_SCALE;
          positions[5] = -(positionsData[bIndex + 1] * cstv.BASE_SCALE);
          positions[4] = positionsData[bIndex + 2] * cstv.BASE_SCALE;

          /*add ground point*/
          groundPoints[0] = groundPoint[0] * cstv.BASE_SCALE; // x
          groundPoints[2] = -(groundPoint[1] * cstv.BASE_SCALE); // z
          groundPoints[1] = 0; // y

          groundPoints[3] = groundPoint[0] * cstv.BASE_SCALE;
          groundPoints[5] = -(groundPoint[1] * cstv.BASE_SCALE);
          groundPoints[4] = 0;

          /*add color data*/
          let start = cstv.colorsForConnections[setSubset].start;
          let end = cstv.colorsForConnections[setSubset].end;
          var color = start.clone().lerp(end.clone(), gradient[j]).toArray();
          _.times(2, () => {
            for (let component of color) {
              colors.push(component);
            }
          });

          /*push data to each layer's array*/
          layerIndexes.forEach(index => {
            if (typeof positionsForLayers[index] === 'undefined')
              positionsForLayers[index] = [];
            if (typeof colorsForLayers[index] === 'undefined')
              colorsForLayers[index] = [];
            if (typeof groundPointsForLayers[index] === 'undefined')
              groundPointsForLayers[index] = [];
            for (var i = 0; i < 6; i++) { // one line has 2 vertices, each is described by vectors3 (xyz, rgb, ...)
              positionsForLayers[index].push(positions[i]); // TODO concat
              colorsForLayers[index].push(colors[i]);
              groundPointsForLayers[index].push(groundPoints[i]);
            }
          });
        }
      }
    });

    /*create meshes*/
    _.times(cstv.SLICE_COUNT, i => {
      let geometry: THREE.BufferGeometry = new THREE.BufferGeometry();
          geometry.addAttribute('position', new THREE.BufferAttribute(new Float32Array(positionsForLayers[i]), 3));
          geometry.addAttribute('color', new THREE.BufferAttribute(new Float32Array(colorsForLayers[i]), 3));
          geometry.addAttribute('ground', new THREE.BufferAttribute(new Float32Array(groundPointsForLayers[i]), 3));
          // geometry.computeBoundingSphere();

      let mesh = new THREE.LineSegments(geometry, this.shader); // , new THREE.LineBasicMaterial({ vertexColors: THREE.VertexColors })
          mesh.matrixAutoUpdate = false;

      this.meshes[setSubset][i] = mesh;
    });

    /*notify*/
    this.onDataLoaded();
  }

}

export default VolumerticMiamiConnections;
