/// <reference path="../../../typings/threejs/three.d.ts" />

/*TODO: fix: "If damping is enabled, you must call controls.update() in your animation loop"*/
/*TODO: check if update filter is needed*/
/*TODO: put request animation frame and epsilon on inertia*/

import Controller from '../controller';
import cst from '../constants';

function clonePreset(initial: Miami.VolMapViewOptions): Miami.VolMapViewOptions {
  return {
    phi: initial.phi,
    theta: initial.theta,
    zoom: initial.zoom,
    target: initial.target.clone()
  };
}

class MapControls {

  /*public api*/
  public current: Miami.VolMapViewOptions = {
    phi: 0,
    theta: 0,
    zoom: 1,
    target: new THREE.Vector3(0, 0, 0)
  };
  public cameraOffsetLength: number;

  /*options*/
  public isEnabled = false;

  public canZoom = true;
  public wheelZoom = true;
  public doubleClickZoom = false;
  public zoomSpeed = 1.0;

  public canRotate = true;
  public rotateSpeed = 1.0;
  public autoRotateSpeed = 2.0;

  public canPan = true;
  public keyPanSpeed = 7.0;  // pixels moved per arrow key push

  public mouseButtons = { ORBIT: THREE.MOUSE.LEFT, ZOOM: THREE.MOUSE.MIDDLE, PAN: THREE.MOUSE.RIGHT };

  // How far you can orbit vertically, upper and lower limits (radians)
  public minPolarAngle = 0;
  public maxPolarAngle = Math.PI;
  // How far you can orbit horizontally, upper and lower limits, must be a sub-interval of the interval [ - Math.PI, Math.PI ]. (radians)
  public minAzimuthAngle = -Infinity;
  public maxAzimuthAngle = Infinity;
  // Limits to how far you can zoom in and out ( PerspectiveCamera only )
  public minZoom = 0;
  public maxZoom = Infinity;
  // // Limits to how far you can pan
  public maxPan = Infinity;
  // Set to true to enable damping (inertia)
  public hasInertia = false;
  public dampingFactor = 0.25;

  /*constants*/
  private STATE = {
    NONE : 'none',
    ROTATE : 'rotate',
    ZOOM : 'zoom',
    ZOOM_ROTATE: 'zoom-rotate',
    PAN : 'pan'
  };

  private EPSILON = 0.000001;

  private state = this.STATE.NONE; // useful for resolving mouse collisions
  private activeTouches = 0;
  private screenDiagonal: number;
  private touchThreshold: number;

  private camera: THREE.PerspectiveCamera;
  private plane: THREE.Plane;
  private domElement: HTMLElement;
  private rayCaster = new THREE.Raycaster();

  /*state*/
  private singlePointerStart = new THREE.Vector2();
  private singlePointerEnd = new THREE.Vector2();
  private twoPointersStart = new THREE.Line3();
  private twoPointersEnd = new THREE.Line3();
  private twoPointersCenter = new THREE.Vector3();

  /*pending changes*/
  private delta: Miami.VolMapViewOptions = {
    phi: 0,
    theta: 0,
    zoom: 1,
    target: new THREE.Vector3()
  };

  /*transitions*/
  private transitionStart: number;
  private transitionDuration: number;
  private requestAnimationFrameId: number;
  private final: Miami.VolMapViewOptions = {};
  private start: Miami.VolMapViewOptions = {};

  /*listeners bound to context*/
  private boundOnMouseMove: EventListener;
  private boundOnMouseUp: EventListener;
  private boundOnTouchMove: EventListener;
  private boundOnTouchEnd: EventListener;

  constructor(private controller: Controller, camera: THREE.PerspectiveCamera,
              plane: THREE.Plane, domElement?: HTMLElement) {

    if (camera instanceof THREE.PerspectiveCamera === false) {
      throw 'Only PerspectiveCamera can be used';
    }
    this.camera = camera;
    this.plane = plane;
    this.domElement = domElement || document.body;
    this.recalculateScreenSize();

    /*bind context to listeners*/
    this.boundOnMouseMove = this.onMouseMove.bind(this);
    this.boundOnMouseUp = this.onMouseUp.bind(this);
    this.boundOnTouchMove = this.onTouchMove.bind(this);
    this.boundOnTouchEnd = this.onTouchEnd.bind(this);

    this.domElement.addEventListener('wheel', this.onMouseWheel.bind(this));
    this.domElement.addEventListener('dblclick', this.onDoubleClick.bind(this));
    this.domElement.addEventListener('contextmenu', (e: MouseEvent) => { e.preventDefault(); });
  }

  /*transition methods*/
  startTransition(preset: Miami.VolMapViewOptions, duration: number,
                  options: { margins: { top: boolean, left: boolean },
                  marginValues: Miami.MapContentOffset }): void {

    this.start = clonePreset(this.current);
    this.final = clonePreset(preset);

    /*calculate offsets for header and sidebar*/
    let visiblePlaneHeight;
    if (options.margins.left) {
      let screenWidthPx = this.domElement.getBoundingClientRect().width;
      let sidebarOffset = options.marginValues.left / 2;
      visiblePlaneHeight = this.final.zoom * Math.tan(this.camera.fov / 2 * Math.PI / 180) * 2;

      let visiblePlaneWidth = visiblePlaneHeight * this.camera.aspect;
      this.final.target.x -= visiblePlaneWidth / screenWidthPx * sidebarOffset;
    }
    if (options.margins.top) {
      let screenHeightPx = this.domElement.getBoundingClientRect().height;
      let headerOffset = options.marginValues.top / 2;
      this.final.target.z -= visiblePlaneHeight / screenHeightPx * headerOffset;
    }
    this.transitionDuration = duration;

    if (duration <= 0) {
      this.setDeltas(1);
      this.update();
    } else {
      this.transitionStart = Date.now();
      this.domElement.classList.add('transition');
      this.transit();
    }
  }

  autoRotate(): void {
    this.requestAnimationFrameId = window.requestAnimationFrame(this.autoRotate.bind(this));
    this.rotateLeft(this.autoRotateSpeed, this.domElement.clientWidth);
    this.update();
  }

  interruptTransition(): void {
    this.domElement.classList.remove('transition');
    window.cancelAnimationFrame(this.requestAnimationFrameId);
  }

  /*direct camera manipulation methods*/
  zoomIn(): void {
    if (!this.isEnabled || !this.canZoom || this.state !== this.STATE.NONE) return;
    // this.interruptTransition();

    this.incrementZoom(0.25);
    this.update();
  }

  zoomOut(): void {
    if (!this.isEnabled || !this.canZoom || this.state !== this.STATE.NONE) return;
    // this.interruptTransition();

    this.decrementZoom(0.25);
    this.update();
  }

  onMouseDown(e: MouseEvent): void {
    if (!this.isEnabled) return;
    // this.interruptTransition();
    e.preventDefault(); // TODO: really needed?

    if (e.button === this.mouseButtons.ORBIT) {
      if (!this.canRotate) return;
      this.state = this.STATE.ROTATE;
      this.singlePointerStart.set(e.clientX, e.clientY);
    } else if (e.button === this.mouseButtons.PAN) {
      if (!this.canPan) return;
      this.state = this.STATE.PAN;
      // For panning we calculate normalized (-1:1) device coordinates
      this.singlePointerStart.x = (e.clientX / document.body.clientWidth) * 2 - 1;
      this.singlePointerStart.y = -(e.clientY / window.innerHeight) * 2 + 1;
    }

    if (this.state !== this.STATE.NONE) {
      this.domElement.addEventListener('mousemove', this.boundOnMouseMove);
      this.domElement.addEventListener('mouseup', this.boundOnMouseUp);
    }
  }

  onTouchStart(e: TouchEvent): void {
    if (!this.isEnabled) return;
    // this.interruptTransition();
    this.activeTouches = e.touches.length;

    switch (this.activeTouches) {
      case 1: // one-fingered touch: pan
        if (!this.canPan) return;
        this.state = this.STATE.PAN;
        this.singlePointerStart.x = (e.touches[0].clientX / document.body.clientWidth) * 2 - 1;
        this.singlePointerStart.y = -(e.touches[0].clientY / window.innerHeight) * 2 + 1;
        break;
      case 2: // two-fingered touch: zoom / rotate
        if (!this.canZoom || !this.canRotate) return;
        this.state = this.STATE.ZOOM_ROTATE;
        this.twoPointersStart.set(
          new THREE.Vector3(e.touches[0].clientX, e.touches[0].clientY),
          new THREE.Vector3(e.touches[1].clientX, e.touches[1].clientY)
        );
        this.twoPointersCenter = this.twoPointersStart.center();
        break;
      default:
        this.singlePointerStart.set(e.touches[0].clientX, e.touches[0].clientY);
        break;
    }

    if (this.state !== this.STATE.NONE) {
      this.domElement.addEventListener('touchmove', this.boundOnTouchMove);
      this.domElement.addEventListener('touchend', this.boundOnTouchEnd);
    }
  }

  /*private*/
  private transit(): void {
    let t = (Date.now() - this.transitionStart) / this.transitionDuration;
    if (t >= 1) {
      this.setDeltas(1);
      this.update();
      this.domElement.classList.remove('transition');
      this.controller.endCameraTransition();
      return;
    }
    this.setDeltas(t);
    this.update();
    this.requestAnimationFrameId = window.requestAnimationFrame(this.transit.bind(this));
  };

  private setDeltas(t: number): void {
    this.delta.phi = (this.final.phi * t + this.start.phi * (1 - t)) - this.current.phi;
    this.delta.theta = (this.final.theta * t + this.start.theta * (1 - t)) - this.current.theta;
    this.delta.zoom = (this.final.zoom * t + this.start.zoom * (1 - t)) / this.current.zoom;
    this.delta.target = new THREE.Vector3().subVectors(
      this.final.target.clone().multiplyScalar(t).add(this.start.target.clone().multiplyScalar(1 - t)),
      this.current.target
    );
  }

  private recalculateScreenSize(): void {
    let el = this.domElement.getBoundingClientRect();
    this.screenDiagonal = Math.sqrt(el.width * el.width + el.height * el.height);
    this.touchThreshold = this.screenDiagonal / 250;
    // console.log(this.screenDiagonal, this.touchThreshold);
  }

  private onTouchMove(e: TouchEvent): void {
    if (!this.isEnabled) return;
    e.preventDefault();
    e.stopPropagation();
    if (this.activeTouches !== e.touches.length) return;

    switch (e.touches.length) {
      case 1: // one-fingered touch: pan
        if (!this.canPan) return;
        this.singlePointerEnd.x = (e.touches[0].clientX / document.body.clientWidth) * 2 - 1;
        this.singlePointerEnd.y = - (e.touches[0].clientY / window.innerHeight) * 2 + 1;
        this.pan();
        this.singlePointerStart.copy(this.singlePointerEnd);
        this.update();
        break;
      case 2: // two-fingered touch: zoom or rotate
        if (!this.canZoom || !this.canRotate) return;
        this.twoPointersEnd.set(
          new THREE.Vector3(e.touches[0].clientX, e.touches[0].clientY),
          new THREE.Vector3(e.touches[1].clientX, e.touches[1].clientY)
        );
        let newCenter = this.twoPointersEnd.center();
        let delta0 = new THREE.Vector3().subVectors(this.twoPointersEnd.start, this.twoPointersCenter).normalize();
        let delta1 = new THREE.Vector3().subVectors(this.twoPointersEnd.end, this.twoPointersCenter).normalize();
        let dot = delta0.dot(delta1);
        if (dot < -0.9997 && this.canZoom) {
          let zoomDelta = this.twoPointersEnd.distance() - this.twoPointersStart.distance();
          let zoomVal = Math.abs(zoomDelta);
          if (zoomVal < this.touchThreshold) return;
          if (zoomDelta > 0) {
            this.incrementZoom(zoomVal / this.screenDiagonal * 4);
          } else if (zoomDelta < 0) {
            this.decrementZoom(zoomVal / this.screenDiagonal * 4);
          }
        } else if (this.canRotate) {
          let rotateDelta = new THREE.Vector3().subVectors(newCenter, this.twoPointersCenter);
          this.rotateLeft(rotateDelta.x, this.domElement.clientWidth);
          this.rotateUp(rotateDelta.y, this.domElement.clientHeight);
        }
        this.twoPointersCenter.copy(newCenter);
        this.twoPointersStart.copy(this.twoPointersEnd);
        this.update();
        break;
      default: // default: rotate
        if (!this.canRotate) return;
        this.singlePointerEnd.set(e.touches[0].clientX, e.touches[0].clientY);
        let rotateDelta = new THREE.Vector2().subVectors(this.singlePointerEnd, this.singlePointerStart);
        this.rotateLeft(rotateDelta.x, this.domElement.clientWidth);
        this.rotateUp(rotateDelta.y, this.domElement.clientHeight);
        this.singlePointerStart.copy(this.singlePointerEnd);
        this.update();
        break;
    }
  }

  private onTouchEnd(): void {
    if (!this.isEnabled) return;
    this.activeTouches = 0;
    this.domElement.removeEventListener('touchmove', this.boundOnTouchMove);
    this.domElement.removeEventListener('touchend', this.boundOnTouchEnd);
    this.state = this.STATE.NONE;
  }

  private onMouseWheel(e: WheelEvent): void {
    if (!this.isEnabled || !this.canZoom || !this.wheelZoom || this.state !== this.STATE.NONE) return;
    // this.interruptTransition();
    e.preventDefault();
    e.stopPropagation();

    if (e.deltaY > 0) {
      this.decrementZoom();
    } else if (e.deltaY < 0) {
      this.incrementZoom();
    }

    this.update();
  }

  private onDoubleClick(e: MouseEvent): void {
    if (!this.isEnabled || !this.canZoom || !this.doubleClickZoom || this.state !== this.STATE.NONE) return;
    // this.interruptTransition();
    e.preventDefault();
    e.stopPropagation();

    if (e.shiftKey) {
      this.decrementZoom(0.25);
    } else {
      this.incrementZoom(0.25);
    }
    this.update();
  }

  private onMouseMove(e: MouseEvent): void {
    if (!this.isEnabled) return;
    e.preventDefault();

    if (this.state === this.STATE.ROTATE) {
      if (!this.canRotate) return;
      this.singlePointerEnd.set(e.clientX, e.clientY);
      let rotateDelta = new THREE.Vector2().subVectors(this.singlePointerEnd, this.singlePointerStart);
      this.rotateLeft(rotateDelta.x, this.domElement.clientWidth);
      this.rotateUp(rotateDelta.y, this.domElement.clientHeight);
      this.singlePointerStart.copy(this.singlePointerEnd);
    } else if (this.state === this.STATE.PAN) {
      if (!this.canPan) return;
      this.singlePointerEnd.x = (e.clientX / document.body.clientWidth) * 2 - 1;
      this.singlePointerEnd.y = - (e.clientY / window.innerHeight) * 2 + 1;
      this.pan();
      this.singlePointerStart.copy(this.singlePointerEnd);
    }

    if (this.state !== this.STATE.NONE) this.update();
  }

  private onMouseUp(): void {
    if (!this.isEnabled) return;
    this.domElement.removeEventListener('mousemove', this.boundOnMouseMove);
    this.domElement.removeEventListener('mouseup', this.boundOnMouseUp);
    this.state = this.STATE.NONE;
  }

  private rotateLeft(rotateDeltaX: number, clientWidth: number): void {
    this.delta.theta -= (2 * Math.PI) * (rotateDeltaX / clientWidth) * this.rotateSpeed;
  }

  private rotateUp(rotateDeltaY: number, clientHeight: number): void {
    this.delta.phi -= (2 * Math.PI) * (rotateDeltaY / clientHeight) * this.rotateSpeed;
  }

  private decrementZoom(speed: number = 0.05): void {
    this.delta.zoom /= Math.pow(1 - speed, this.zoomSpeed);
  }

  private incrementZoom(speed: number = 0.05): void {
    this.delta.zoom *= Math.pow(1 - speed, this.zoomSpeed);
  }

  private pan(): void {
    this.rayCaster.setFromCamera(this.singlePointerStart, this.camera);
    let startPoint = this.rayCaster.ray.intersectPlane(this.plane);

    this.rayCaster.setFromCamera(this.singlePointerEnd, this.camera);
    let endPoint = this.rayCaster.ray.intersectPlane(this.plane);

    this.delta.target.add(startPoint.sub(endPoint));
  }

  private update(): void {
    let cameraOffset = new THREE.Vector3();

    // so camera.up is the orbit axis
    let quat = new THREE.Quaternion().setFromUnitVectors(this.camera.up, new THREE.Vector3(0, 1, 0));
    let quatInverse = quat.clone().inverse();

    cameraOffset.copy(this.camera.position).sub(this.current.target);
    cameraOffset.applyQuaternion(quat); // rotate cameraOffset to "y-axis-is-up" space

    /*orbit*/
    this.current.theta = Math.atan2(cameraOffset.x, cameraOffset.z);
    this.current.theta += this.delta.theta;
    this.current.phi = Math.atan2(Math.sqrt(cameraOffset.x * cameraOffset.x + cameraOffset.z * cameraOffset.z), cameraOffset.y);
    this.current.phi += this.delta.phi;

    this.current.theta = Math.max(this.minAzimuthAngle, Math.min(this.maxAzimuthAngle, this.current.theta)); // restrict theta
    this.current.phi = Math.max(this.minPolarAngle, Math.min(this.maxPolarAngle, this.current.phi)); // restrict phi
    this.current.phi = Math.max(this.EPSILON, Math.min(Math.PI - this.EPSILON, this.current.phi)); // restrict phi to be between EPS and PI-EPS

    /*zoom*/
    this.current.zoom *= this.delta.zoom;
    this.current.zoom = Math.max(this.minZoom, Math.min(this.maxZoom, this.current.zoom)); // restrict this.current.zoom
    this.controller.mapZoomLimit({
      in: this.current.zoom === this.minZoom,
      out: this.current.zoom === this.maxZoom
    });

    /*pan*/
    this.current.target.add(this.delta.target);
    this.current.target.clampLength(0, this.maxPan);

    /*application of changes*/
    cameraOffset.x = this.current.zoom * Math.sin(this.current.phi) * Math.sin(this.current.theta);
    cameraOffset.y = this.current.zoom * Math.cos(this.current.phi);
    cameraOffset.z = this.current.zoom * Math.sin(this.current.phi) * Math.cos(this.current.theta);
    cameraOffset.applyQuaternion(quatInverse); // rotate cameraOffset back to "camera-up-vector-is-up" space

    this.camera.position.copy(this.current.target).add(cameraOffset);
    this.camera.lookAt(this.current.target);

    /*save camera offset*/
    this.cameraOffsetLength = cameraOffset.length();

    /*pending changes values to normal*/
    if (this.hasInertia && this.delta.theta > 0.01 && this.delta.phi > 0.01) {
      this.delta.theta *= (1 - this.dampingFactor);
      this.delta.phi *= (1 - this.dampingFactor);
    } else {
      this.delta.theta = 0;
      this.delta.phi = 0;
    }

    this.delta.zoom = 1;
    this.delta.target.set(0, 0, 0);
  }

}

export default MapControls;
