/// <reference path="../../../typings/tsd.d.ts" />

import cst from './../constants';
import TweetGroup from './../tweet_group';
import Controller from './../controller';
import * as _ from 'lodash';

class Zones {
  private tweets: TweetGroup;

  constructor(el: string, private controller: Controller) {
    this.tweets = new TweetGroup(`${el} .tweet-group`, 'data/zones_tweets.json', cst.tweet_count);
    this.tweets.display()
      .then(() => this.controller.recalculateSections())
      .then(() => this.controller.updateComponentState('zones', true)); // HACK

    this.controller.on(cst.event.F_LAYER_CHANGED, this.onLayerChange, this);
    this.controller.on(cst.event.TOTAL_STATE_UPDATE, this.onTotalStateUpdate, this);
  }

  private onLayerChange(state: Miami.State): void {
    if (state.section !== cst.section.ZONES) return;
    this.tweets.display(this.layers(state));
  }

  private onTotalStateUpdate(state: Miami.State): void {
    this.tweets.display(this.layers(state))
      .then(() => this.controller.recalculateSections());
  }

  private layers(state: Miami.State): string[] {
    let layers = state.flatLayers[cst.section.ZONES];
    return <string[]> _(layers).map(layer => layer.replace('zone-', '')).value();
  }
}

export default Zones;
