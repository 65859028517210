/// <reference path="../../typings/tsd.d.ts" />

import * as _ from 'lodash';
import Controller from './controller';

class Chart {
  protected svg: any;
  protected container: any = {};
  protected scales: any = {};
  protected axis: any = {};
  protected controller: Controller;

  constructor(protected config: Miami.ChartOptions) {
    this.container.margins = _.assign({top: 0, right: 0, bottom: 0, left: 0}, config.margins);

    this.controller = config.controller;
    this.updateBounds();

    this.svg = config.el
      .append('svg')
      .attr('width', this.container.trueWidth)
      .attr('height', this.container.trueHeight)
      .append('g')
      .attr('transform', `translate(${this.container.margins.left}, ${this.container.margins.top})`);
  }

  protected updateBounds(): void {
    this.container.trueWidth = this.config.el.node().clientWidth;
    this.container.trueHeight = this.config.el.node().clientHeight;
    this.container.width = this.container.trueWidth - this.container.margins.right - this.container.margins.left;
    this.container.height = this.container.trueHeight - this.container.margins.top - this.container.margins.bottom;
  }
}

export default Chart;
