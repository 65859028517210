/// <reference path="../../typings/tsd.d.ts" />

import Chart from './chart';

class SentimentsChart extends Chart {
  constructor(protected config: Miami.ChartOptions) {
    super(config);

    this.defineScales();
    this.drawChart();
  }

  private defineScales(): void {
    this.scales = {
      x: d3.scale.linear().range([0, this.container.width]),
      y0: d3.scale.ordinal().rangeRoundBands([this.container.height, 0], .1),
      y1: d3.scale.ordinal(),
    };

    this.axis.y = d3.svg.axis().scale(this.scales.y0).orient('left').ticks(0);
  }

  private drawChart(): void {
    d3.csv(this.config.dataUrl, (error, data) => {
      let qualities = d3.keys(data[0]).filter(key => key !== this.config.category);

      for (var datum of data) {
        datum.tweets = qualities.map(quality => { return {quality: quality, value: +datum[quality]}; });
      }

      this.scales.x.domain([0, d3.max(data, d => d3.max(d['tweets'], quality => quality['value']))]);
      this.scales.y0.domain(data.map(d => d[this.config.category]).reverse());
      this.scales.y1.domain(qualities).rangeRoundBands([0, this.scales.y0.rangeBand()], 0, 1);

      let category = this.svg
        .selectAll('.' + this.config.category)
        .data(data)
        .enter()
        .append('g')
        .attr('transform', d => `translate(0, ${this.scales.y0(d[this.config.category])})`);

      category
        .selectAll('rect')
        .data(d => d.tweets)
        .enter()
        .append('rect')
        .attr('height', this.scales.y1.rangeBand())
        .attr('x', 0)
        .attr('y', d => this.scales.y1(d.quality))
        .attr('width', d => this.scales.x(d.value) || 1)
        .attr('class', d => d.quality);

      category
        .selectAll('text')
        .data(d => d.tweets)
        .enter()
        .append('text')
        .attr('x', d => this.scales.x(d.value) + 5)
        .attr('y', d => this.scales.y1(d.quality) + this.scales.y1.rangeBand() - 2)
        .text(d => d.value)
        .attr('class', 'value');

      this.svg
        .append('g')
        .call(this.axis.y)
        .selectAll('text')
        .style('text-anchor', 'start')
        .attr('class', 'label')
        .attr('transform', `translate(9, ${-this.scales.y1.rangeBand() * 1.8})`);
    });
  }

}

export default SentimentsChart;

