/// <reference path='../../typings/tsd.d.ts' />

class SVGMask {
  private $: any = {};
  private _x: any;
  private _y: any;
  private revealExtent: any[];

  constructor(private svg: any) {
    this.$.mask = this.svg.append('g').attr('class', 'mask');
    this.$.left = this.$.mask.append('polygon');
    this.$.right = this.$.mask.append('polygon');
  }

  style(prop: string, val: string): SVGMask {
    this.$.left.style(prop, val);
    this.$.right.style(prop, val);

    return this;
  }

  x(newX?: any): any {
    if (newX === undefined) return this._x;
    this._x = newX;
    return this;
  }

  y(newY?: any): any {
    if (newY === undefined) return this._y;
    this._y = newY;
    return this;
  }

  redraw(): SVGMask {
    let [minY, maxY] = this._y.domain();
    let [minX, maxX] = this._x.domain();

    let lPart = {
      left: this._x(minX),
      top: this._y(minY),
      right: this._x(this.revealExtent[0]),
      bottom: this._y(maxY)
    };

    let rPart = {
      left: this._x(this.revealExtent[1]),
      top: this._y(minY),
      right: this._x(maxX),
      bottom: this._y(maxY)
    };

    this.$.left
      .attr('points', `${lPart.left},${lPart.top} ${lPart.right},${lPart.top} `
          + `${lPart.right},${lPart.bottom} ${lPart.left},${lPart.bottom}`);
    this.$.right
      .attr('points', `${rPart.left},${rPart.top} ${rPart.right},${rPart.top} `
          + `${rPart.right},${rPart.bottom} ${rPart.left},${rPart.bottom}`);

    return this;
  }

  reveal(extent: any[]): SVGMask {
    this.revealExtent = extent;
    this.redraw();

    return this;
  };
}

export default SVGMask;
