/// <reference path="../../../typings/tsd.d.ts" />

import cst from './../constants';
import Controller from './../controller';
import TweetGroup from './../tweet_group';

class Neighborhoods {
  private currentNeighborhood: any;
  private tweets: TweetGroup;
  private neighborhoodsInfo: any;

  constructor(el: string, private controller: Controller) {
    this.currentNeighborhood = d3.select('#current-neighborhood');
    this.tweets = new TweetGroup(`${el} .tweet-group`, 'data/neighbourhoods_tweets.json', cst.tweet_count);

    this.controller.on(cst.event.F_LAYER_CHANGED, this.onLayerChange, this);
    this.controller.on(cst.event.TOTAL_STATE_UPDATE, this.onLayerChange, this);
  }

  private onLayerChange(state: Miami.State): void {
    if (state.section !== cst.section.NEIGHBORHOODS) return;
    this.loadInfo('data/neighbourhoods.json')
      .then(() => this.toggleNeighborhood(state));
  }

  private loadInfo(url: string): any {
    if (this.neighborhoodsInfo) {
      return Promise.resolve();
    } else {
      return (<any>d3.promise).json(url)
        .then(data => this.neighborhoodsInfo = data);
    }
  }

  private toggleNeighborhood(state: Miami.State): void {
    let neighborhood = this.activeLayer(state);

    if (this.neighborhoodsInfo[neighborhood]) {
      this.showNeigbourhood(state);
    } else if (!this.currentNeighborhood.classed('hidden')) {
      this.hideNeighborhood();
    }

    this.scroll(state);
  }

  private showNeigbourhood(state: Miami.State): void {
    let neighborhood = this.activeLayer(state);

    this.currentNeighborhood
      .select('.neighbourhood-title')
      .html(this.neighborhoodsInfo[neighborhood].title);
    this.currentNeighborhood
      .select('.neighbourhood-info')
      .html(this.neighborhoodsInfo[neighborhood].text);

    this.tweets.display(neighborhood)
      .then(() => this.currentNeighborhood.classed('hidden', false))
      .then(() => this.controller.recalculateSections())
      .then(() => this.scroll(state));
  }

  private hideNeighborhood(): void {
    this.currentNeighborhood.classed('hidden', true);
    this.controller.recalculateSections();
  }

  private canScroll(state: Miami.State): boolean {
    return !state.mapManual && !state.volExploreMode[state.section];
  }

  private activeLayer(state: Miami.State): string {
    return state.flatLayers[state.section][0];
  }

  private scroll(state: Miami.State): void {
    if (this.canScroll(state)) this.controller.scrollToSection(state.section);
  }
}

export default Neighborhoods;
