var LeafletInstagram = L.Class.extend({

  _images: [],

  initialize: function(images) {
    this._images = images;
    this.XMLNS = 'http://www.w3.org/2000/xmlns/';
    this.SVGNS = 'http://www.w3.org/2000/svg';
    this.XLINKNS = 'http://www.w3.org/1999/xlink';
  },

  onAdd: function(map) {
    this._map = map;
    // create a DOM element and put it into one of the map panes
    this._pane = map.getPanes().overlayPane;
    for (var i = this._images.length - 1; i >= 0; i--) {

      var svg = document.createElementNS(this.SVGNS, 'svg');
      svg.setAttributeNS(null, 'class', 'leaflet-instagram-frame');
      // svg.setAttributeNS(null, 'viewBox', '0 0 64 64');
      svg.setAttributeNS(this.XMLNS, 'xmlns', this.SVGNS);
      svg.setAttributeNS(this.XMLNS, 'xmlns:xlink', this.XLINKNS);

      var image = document.createElementNS(this.SVGNS, 'image');
      image.setAttributeNS(null, 'class', 'leaflet-instagram-image');
      image.setAttributeNS(this.XLINKNS, 'xlink:href', this._images[i].img);
      image.setAttributeNS(null, 'width', '100%');
      image.setAttributeNS(null, 'height', '100%');
      image.setAttributeNS(null, 'clip-path', 'url(#instagramClipper)');
      image.setAttributeNS(null, 'data-latlong', this._images[i].latlong);
      image.setAttributeNS(null, 'data-url', this._images[i].url);
      // @ifndef EXHIBIT
      image.addEventListener('click', function (e) {
        window.open(e.target.getAttribute('data-url'));
      });
      // @endif

      svg.appendChild(image);
      this._pane.appendChild(svg);

    }
    // add a viewreset event listener for updating layer's position, do the latter
    map.on('viewreset', this.reset, this);
    this.reset();
  },

  onRemove: function(map) {
    // remove layer's DOM elements and listeners
    while (this._pane.firstChild) {
      this._pane.removeChild(this._pane.firstChild);
    }
    map.off('viewreset', this.reset, this);
  },

  addTo: function(map) {
    map.addLayer(this);
    return this;
  },

  reset: function() {
    // update layer's position
    for (var i = this._pane.childNodes.length - 1; i >= 0; i--) {
        if (this._pane.childNodes[i].getAttribute('class') === 'leaflet-instagram-frame') {
          var imageLatLong = this._pane.childNodes[i].firstChild.getAttribute('data-latlong').split(',').map(parseFloat);
          var pos = this._map.latLngToLayerPoint(imageLatLong);
          L.DomUtil.setPosition(this._pane.childNodes[i], pos);
        }
    }
  }

});

module.exports = function(args) { return new LeafletInstagram(args); };
