/// <reference path="../../typings/tsd.d.ts" />

import * as _ from 'lodash';

namespace utils {

  export function datasetName(set: string, subset: string): string {
    return (set && subset) ? `${set}.${subset}` : null;
  };

  export function fullDataset(dataset: Miami.VolumetricData): boolean {
    return !_.isEmpty(dataset.top) && !_.isEmpty(dataset.bottom);
  }

  export function isSameDataset(a: Miami.SetSubset, b: Miami.SetSubset): boolean {
    return (a && b) && (a.set === b.set) && (a.subset === b.subset);
  }

  export function clone<T>(obj: T): T  {
    let newObj: T = <T>{};
    for (let prop in obj) {
      if (!obj.hasOwnProperty(prop)) continue;
      newObj[prop] = obj[prop];
    }
    return newObj;
  };

  export function map(aX: number, aMin: number, aMax: number, bMin: number, bMax: number): number {
    let aRange = aMax - aMin;
    let bRange = bMax - bMin;
    let aXScaled = (aX - aMin) / aRange;
    return bMin + (aXScaled * bRange);
  };

  export function geoBoundsWidth(map: L.Map, bounds: L.LatLngBounds, zoomlvl: number = 12): number {
    let borderA = map.project(bounds.getSouthWest(), zoomlvl);
    let borderB = map.project(bounds.getSouthEast(), zoomlvl);
    return Math.abs(borderA.x - borderB.x);
  }

  export function dateToIntegers(dateRange: Date[]): number[] {
    // dataset has 12 month: from March 2014 to February 2015. The functions converts values into range 0 - 11
    return dateRange.map((date: Date) => { return date.getFullYear() === 2014 ? date.getMonth() - 2 : date.getMonth() + 10; });
  }

  export function long2tile(long: number, zoom: number): Geo.PointOnTile {
    let precise = (long + 180) / 360 * Math.pow(2, zoom);
    return { tileNum: Math.floor(precise), innerOffset: precise % 1 };
  };

  export function lat2tile(lat: number, zoom: number): Geo.PointOnTile {
    let precise = (1 - Math.log(Math.tan(lat * Math.PI / 180) + 1 / Math.cos(lat * Math.PI / 180)) / Math.PI) / 2 * Math.pow(2, zoom);
    return { tileNum: Math.floor(precise), innerOffset: precise % 1 };
  };

  export function tile2long(x: number, z: number): number {
    return (x / Math.pow(2, z) * 360 - 180 );
  }

  export function toNormalizedDeviceCoordinates(pointerX: number, pointerY: number): THREE.Vector2 {
    let normalizedPointer = new THREE.Vector2();
    normalizedPointer.x = (pointerX / document.body.clientWidth) * 2 - 1;
    normalizedPointer.y = -(pointerY / window.innerHeight) * 2 + 1;
    return normalizedPointer;
  }

};

export default utils;
