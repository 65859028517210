/// <reference path="../../../typings/tsd.d.ts" />

import cst from '../constants.ts';
import utils from '../utils';
import { clone, cloneDeep } from 'lodash';

interface BlobColorPair {
  light: THREE.Color;
  shade: THREE.Color;
}

interface ConnectionColorPair {
  start: THREE.Color,
  end: THREE.Color,
  ring: THREE.Color
}

namespace cstv {
  export const EPSILON = 0.000001;
  export const BASE_SCALE = 0.1;
  export const SLICE_STEP = 0.1; // margin between blobs
  export const SLICE_COUNT = 12;

  export const CONNECTION_PARTS = ['start', 'middle', 'end'];
  export const sets = { MIRACLE: 'miraclemile', HOODS: 'catchmentarea' };
  export const subsets = { FROM: 'from', ABOUT: 'about' };
  export const colors = {
    BLUE: {light: new THREE.Color().setStyle('#79D1F4'), shade: new THREE.Color().setStyle('#4C849C') },
    YELLOW: {light: new THREE.Color().setStyle('#F6D83F'), shade: new THREE.Color().setStyle('#C8902B') },
    PURPLE: {light: new THREE.Color().setStyle('#8CA0F2'), shade: new THREE.Color().setStyle('#616EAC') },
    ORANGE: {light: new THREE.Color().setStyle('#fe8a4c'), shade: new THREE.Color().setStyle('#ab532e') }
  };

  export const colorsBySet: { [set: string]: { from: BlobColorPair, about: BlobColorPair } } = {};

    cstv.colorsBySet[cstv.sets.MIRACLE] = { from: cstv.colors.YELLOW, about: cstv.colors.BLUE };
    cstv.colorsBySet[cstv.sets.HOODS] = { from: cstv.colors.PURPLE, about: cstv.colors.ORANGE };

  export const colorsForBlobs = {};

    cstv.colorsForBlobs[utils.datasetName(cstv.sets.MIRACLE, cstv.subsets.ABOUT)] = cstv.colorsBySet[cstv.sets.MIRACLE].about;
    cstv.colorsForBlobs[utils.datasetName(cstv.sets.MIRACLE, cstv.subsets.FROM)] = cstv.colorsBySet[cstv.sets.MIRACLE].from;
    cstv.colorsForBlobs[utils.datasetName(cstv.sets.HOODS, cstv.subsets.ABOUT)] = cstv.colorsBySet[cstv.sets.HOODS].about;
    cstv.colorsForBlobs[utils.datasetName(cstv.sets.HOODS, cstv.subsets.FROM)] = cstv.colorsBySet[cstv.sets.HOODS].from;

  export const colorsForConnections: { [setSubset: string]: ConnectionColorPair } = {};

    cstv.colorsForConnections[utils.datasetName(cstv.sets.MIRACLE, cstv.subsets.ABOUT)] = { start: cstv.colors.BLUE.light, end: cstv.colors.YELLOW.light, ring: cstv.colors.BLUE.shade };
    cstv.colorsForConnections[utils.datasetName(cstv.sets.MIRACLE, cstv.subsets.FROM)] = { start: cstv.colors.BLUE.light, end: cstv.colors.YELLOW.light, ring: cstv.colors.YELLOW.shade };
    cstv.colorsForConnections[utils.datasetName(cstv.sets.HOODS, cstv.subsets.ABOUT)] = { start: cstv.colors.BLUE.light, end: cstv.colors.ORANGE.light, ring: cstv.colors.BLUE.shade };
    cstv.colorsForConnections[utils.datasetName(cstv.sets.HOODS, cstv.subsets.FROM)] = { start: cstv.colors.PURPLE.light, end: cstv.colors.YELLOW.light, ring: cstv.colors.YELLOW.shade };

  export const UNDEFINED_AREA_LENS_COLOR = new THREE.Color().setStyle('#999999');

  export const colorInterpolationStep = 1 / cstv.SLICE_COUNT;

  /**
   * HACK: This thing make volumetric map zoom value just like mapbox 12 zoom level
   * On resize it updates
   * 53.94 - perfect value for 990 window.innerHeight
   * 18.35 - magic coefficient that makes this function work for other window heights
   * TODO: make it better, if needed
   */

  const zoomLikeMapbox12 = () => 53.94 - (990 - window.innerHeight) / 18.35;

  let viewOptionsDefault = {
    target: new THREE.Vector3(0, 0, 0),
    zoom: zoomLikeMapbox12(),
    phi: Math.PI / 3,
    theta: 0,
  };

  export let viewOptionsForTransition: Miami.VolMapViewOptions = {
    target: new THREE.Vector3(0, 0, 0),
    zoom: zoomLikeMapbox12(),
    phi: 0,
    theta: 0
  };

  window.addEventListener('resize', () => {
    viewOptionsForTransition.zoom = zoomLikeMapbox12();
    viewOptionsForSection[cst.section.SEMANTIC].zoom = zoomLikeMapbox12();
    viewOptionsForSection[cst.section.COMPETITORS].zoom = zoomLikeMapbox12();
    viewOptionsForSection[cst.section.COMMUNITY].zoom = zoomLikeMapbox12();
    viewOptionsForSection[cst.section.TARGET_AUDIENCE].zoom = zoomLikeMapbox12();
    viewOptionsForSection[cst.section.POTENTIAL_COMPETITORS].zoom = zoomLikeMapbox12();
    viewOptionsForSection[cst.section.EXPLORE].zoom = zoomLikeMapbox12();
  });

  export const viewOptionsForSection: { [section: string]: Miami.VolMapViewOptions } = {};
    viewOptionsForSection[cst.section.SEMANTIC] = clone(viewOptionsDefault);
    viewOptionsForSection[cst.section.COMPETITORS] = clone(viewOptionsDefault);
    viewOptionsForSection[cst.section.COMMUNITY] = clone(viewOptionsDefault);
    viewOptionsForSection[cst.section.TARGET_AUDIENCE] = clone(viewOptionsDefault);
    viewOptionsForSection[cst.section.POTENTIAL_COMPETITORS] = clone(viewOptionsDefault);
    // HACK: these three sections use the same object, so when there are changes
    // on Explore, same dataset is visible when scrolling to the Summary or Credits
    // (see also controller.ts:61)
    viewOptionsForSection[cst.section.EXPLORE] = clone(viewOptionsDefault);
    viewOptionsForSection[cst.section.SUMMARY] = viewOptionsForSection[cst.section.EXPLORE];
    viewOptionsForSection[cst.section.CREDITS] = viewOptionsForSection[cst.section.EXPLORE];

  /*defaults*/
  export const defaultDataForSections: { [section: string]: Miami.VolumetricData } = {};
    defaultDataForSections[cst.section.SEMANTIC] = {
      top: null,
      bottom: null
    };
    defaultDataForSections[cst.section.COMPETITORS] = {
      top: { set: cstv.sets.MIRACLE, subset: cstv.subsets.FROM },
      bottom: null
    };
    defaultDataForSections[cst.section.COMMUNITY] = {
      top: { set: cstv.sets.MIRACLE, subset: cstv.subsets.ABOUT },
      bottom: null
    };
    defaultDataForSections[cst.section.TARGET_AUDIENCE] = {
      top: { set: cstv.sets.MIRACLE, subset: cstv.subsets.FROM },
      bottom: { set: cstv.sets.HOODS, subset: cstv.subsets.FROM }
    };
    defaultDataForSections[cst.section.POTENTIAL_COMPETITORS] = {
      top: { set: cstv.sets.MIRACLE, subset: cstv.subsets.ABOUT },
      bottom: {set: cstv.sets.HOODS, subset: cstv.subsets.ABOUT }
    };
    // HACK: same as viewOptionsForSection
    defaultDataForSections[cst.section.EXPLORE] = {
      top: { set: cstv.sets.MIRACLE, subset: cstv.subsets.ABOUT },
      bottom: {set: cstv.sets.HOODS, subset: cstv.subsets.ABOUT }
    }
    defaultDataForSections[cst.section.SUMMARY] = defaultDataForSections[cst.section.EXPLORE];
    defaultDataForSections[cst.section.CREDITS] = defaultDataForSections[cst.section.EXPLORE];

  export const defaultTimeRange = [new Date(2014, 4, 1), new Date(2014, 11, 1)];

  export const fullTimeRange = [new Date(2014, 2, 1), new Date(2015, 2, 1)];

  export const defaultRangesForSections: { [section: string]: Date[] } = {};
    defaultRangesForSections[cst.section.SEMANTIC] = cloneDeep(defaultTimeRange);
    defaultRangesForSections[cst.section.COMPETITORS] = cloneDeep(defaultTimeRange);
    defaultRangesForSections[cst.section.COMMUNITY] = cloneDeep(defaultTimeRange);
    defaultRangesForSections[cst.section.TARGET_AUDIENCE] = cloneDeep(defaultTimeRange);
    defaultRangesForSections[cst.section.POTENTIAL_COMPETITORS] = cloneDeep(defaultTimeRange);
    // HACK: same as viewOptionsForSection
    defaultRangesForSections[cst.section.EXPLORE] = cloneDeep(defaultTimeRange);
    defaultRangesForSections[cst.section.SUMMARY] = defaultRangesForSections[cst.section.EXPLORE];
    defaultRangesForSections[cst.section.CREDITS] = defaultRangesForSections[cst.section.EXPLORE];

  export const fullObjectVisibility = {
    blobs: { top: true, bottom: true },
    connections: { top: true, bottom: true }
  };

  export const defaultVisibilityForSections: { [section: string]: Miami.VolumetricObjectsVisibility } = {};
    defaultVisibilityForSections[cst.section.SEMANTIC] = cloneDeep(fullObjectVisibility);
    defaultVisibilityForSections[cst.section.COMPETITORS] = cloneDeep(fullObjectVisibility);
    defaultVisibilityForSections[cst.section.COMMUNITY] = cloneDeep(fullObjectVisibility);
    defaultVisibilityForSections[cst.section.TARGET_AUDIENCE] = cloneDeep(fullObjectVisibility);
    defaultVisibilityForSections[cst.section.POTENTIAL_COMPETITORS] = cloneDeep(fullObjectVisibility);
    // HACK: same as with defaultDataForSections
    defaultVisibilityForSections[cst.section.EXPLORE] = cloneDeep(fullObjectVisibility);
    defaultVisibilityForSections[cst.section.SUMMARY] = defaultVisibilityForSections[cst.section.EXPLORE];
    defaultVisibilityForSections[cst.section.CREDITS] = defaultVisibilityForSections[cst.section.EXPLORE];

  export const areaLensStatusForSection: { [section: string]: boolean } = {};
    areaLensStatusForSection[cst.section.SEMANTIC] = false;
    areaLensStatusForSection[cst.section.COMPETITORS] = true;
    areaLensStatusForSection[cst.section.COMMUNITY] = true;
    areaLensStatusForSection[cst.section.TARGET_AUDIENCE] = true;
    areaLensStatusForSection[cst.section.POTENTIAL_COMPETITORS] = true;
    areaLensStatusForSection[cst.section.SUMMARY] = false;
    areaLensStatusForSection[cst.section.EXPLORE] = false;
    areaLensStatusForSection[cst.section.CREDITS] = false;

  export const defaultExploreModeForSections: { [section: string]: boolean } = {};
    defaultExploreModeForSections[cst.section.EXPLORE] = false;

  // For full timerange of Community(top)/Potential competitors(bottom)
  export const highlights = [
    new THREE.Vector3(0, 0, 0),
    new THREE.Vector3(12.07, 0, -4.2),
    new THREE.Vector3(5.92, 0, -2.38),
    new THREE.Vector3(13.48, 0, -21.15),
    new THREE.Vector3(-2.49, 0, -21.92),
    new THREE.Vector3(-5.7, 0, 7.28),
    new THREE.Vector3(-9.9, 0, 0)
  ];
}

export default cstv;
