/// <reference path="../../typings/tsd.d.ts" />

import SVGMask from './svg_mask.ts';
import cst from './constants.ts';
import Controller from './controller.ts';

class Slider {
  extent: Date[];
  private brush: any;
  private gBrush: d3.Selection<any>;
  private mask: SVGMask;
  private timeout: number = 600;
  private interval: any = null;

  constructor(private svg: any, private container: any, private scales: any, private controller: Controller) {
    this.brush = d3.svg
      .brush()
      .x(this.scales.x)
      .on('brush', this.onBrush.bind(this))
      .on('brushend', this.onBrushEnd.bind(this));
    this.scales.yTotal = d3.scale.linear().range([this.container.height - 15, 15]);
    this.controller.on(cst.event.V_PLAY, this.play, this);
    this.controller.on(cst.event.V_STOP, this.stop, this);
  }

  appendBrush(): void {
    this.gBrush = this.svg
      .append('g')
      .attr('class', 'brush')
      .call(this.brush);

    this.gBrush.selectAll('rect')
      .attr('height', this.container.height);

    this.gBrush.select('rect.extent')
      .attr('rx', 4);

    this.gBrush.select('.resize.e')
      .append('path')
      .attr('class', 'handle')
      .attr('d', this.rightHandle(0, this.container.height / 4, 1, this.container.height / 2, 4));

    this.gBrush.select('.resize.w')
      .append('path')
      .attr('class', 'handle')
      .attr('d', this.leftHandle(0, this.container.height / 4, 1, this.container.height / 2, 4));
  }

  fixedExtent(type: string): Date[]  {
    switch (type) {
      case 'single':
        return [this.brush.extent()[0], this.nextMonth(this.brush.extent()[0])];
      case 'all':
        return this.scales.x.domain();
      default:
        return null;
    }
  }

  drawMask(): void {
    this.mask = new SVGMask(this.svg)
      .x(this.scales.x)
      .y(this.scales.yTotal);
  }

  play(): void {
    this.extent = this.brush.extent();
    this.interval = setInterval(this.slide.bind(this), this.timeout);
  }

  stop(): void {
    clearInterval(this.interval);
    this.interval = null;
  }

  isPlaying(): boolean {
    return this.interval !== null;
  }

  moveBrush(extent: Date[]): void {
    this.gBrush
      .transition()
      .call(this.brush.extent(extent))
      .call(this.brush.event)
      .each('start', () => this.disableUserInteraction(true))
      .each('end', () => this.disableUserInteraction(false))
      .each('interrupt', () => this.disableUserInteraction(false));
  }

  redraw(): void {
    this.mask.reveal(this.brush.extent());
    this.gBrush
      .call(this.brush.extent(this.brush.extent()))
      .call(this.brush.event);
  }

  private slide(): void {
    let currentExtent = this.brush.extent();
    let moveTo;

    if (this.canGrow(currentExtent)) {
      moveTo = [currentExtent[0], this.nextMonth(currentExtent[1])];
    } else if (this.canSlide(currentExtent)) {
      moveTo = [this.nextMonth(currentExtent[0]), this.nextMonth(currentExtent[1])];
    } else if (this.canShrink(currentExtent)) {
      moveTo = [this.nextMonth(currentExtent[0]), currentExtent[1]];
    } else {
      moveTo = [this.scales.x.domain()[0], this.nextMonth(this.scales.x.domain()[0])];
    }

    this.controller.changeRange(moveTo);
  }

  private canGrow(currentExtent: Date[]): boolean {
    return currentExtent[1] < this.scales.x.domain()[1]
      && this.range(currentExtent) < this.range(this.extent);
  }

  private canSlide(currentExtent: Date[]): boolean {
    return currentExtent[1] < this.scales.x.domain()[1];
  }

  private canShrink(currentExtent: Date[]): boolean {
    return +currentExtent[1] === +this.scales.x.domain()[1]
      && this.range(currentExtent) <= this.range(this.extent)
      && this.range(currentExtent) > 1;
  }

  private range(dates: Date[]): number {
    return (dates[1].getMonth() + 12 * dates[1].getFullYear())
      - (dates[0].getMonth() + 12 * dates[0].getFullYear());
  }

  private nextMonth(date: Date): Date {
    return d3.time.month.offset(date, 1);
  }

  private onBrush(): void {
    this.mask.reveal(this.brush.extent());
  }

  private onBrushEnd(): void {
    let event = <any>d3.event;
    if (!event.sourceEvent) return;
    if (event.sourceEvent.type) this.controller.stop();

    let extent = this.brush.extent();
    let roundedExtent = extent.map(d3.time.month.round);

    if (roundedExtent[0] >= roundedExtent[1]) {
      roundedExtent = [d3.time.month.floor(extent[0]), d3.time.month.ceil(extent[1])];
    }

    this.extent = roundedExtent;
    this.controller.changeRange(roundedExtent);
  }

  private disableUserInteraction(disable: boolean) {
    if (disable && this.isPlaying()) return;
    this.gBrush.style('pointer-events', disable ? 'none' : 'all');
  }

  private rightHandle(x: number, y: number, width: number, height: number, radius: number): string {
    return `M${x},${y}`
      + `a${radius},${radius} 0 0 1 ${radius},${radius}`
      + `v${height - 2 * radius}`
      + `a${radius},${radius} 0 0 1 ${-radius},${radius}`
      + 'z';
  };

  private leftHandle(x: number, y: number, width: number, height: number, radius: number): string {
    return `M${x},${y}`
      + `a${radius},${radius} 0 0 0 ${-radius},${radius}`
      + `v${height - 2 * radius}`
      + `a${radius},${radius} 0 0 0 ${radius},${radius}`
      + 'z';
  };
}

export default Slider;
