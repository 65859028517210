/// <reference path="../../typings/tsd.d.ts" />

import { values } from 'lodash';
import cst from './constants';

namespace cstf {

  export const geobounds = {
    UNIVERSE: L.latLngBounds([[32.35, -90.9], [22, -77]]),
    ALL_TWEETS: L.latLngBounds([[25.6369, -80.4043], [25.9889, -80.1157]]),
    HOOD_CORAL_GABLES: L.latLngBounds([[25.6248, -80.3032], [25.7733, -80.2428]]),
    HOOD_WYNWOOD: L.latLngBounds([[25.7931, -80.2091], [25.8153, -80.1892]]),
    HOOD_MIDTOWN: L.latLngBounds([[25.8022, -80.1976], [25.8135, -80.1875]]),
    HOOD_COCOWALK: L.latLngBounds([[25.7266, -80.245], [25.7314, -80.2401]]),
    HOOD_MERRICK: L.latLngBounds([[25.7291, -80.2629], [25.734, -80.2575]]),
    HOOD_DESIGN: L.latLngBounds([[25.8102, -80.1968], [25.8163, -80.1885]]),
    HOOD_MIRACLE_MILE: L.latLngBounds([[25.7471, -80.2635], [25.7522, -80.254]]),
    HOOD_DADELAND: L.latLngBounds([[25.6883, -80.3174], [25.6923, -80.3065]]),
    OLD_GABLES: L.latLngBounds([[25.7316, -80.2904], [25.7661, -80.2521]]),
    MIAMI: L.latLngBounds([[25.6242, -80.4055], [25.9908, -80.1151]]),
    MIRACLE_MILE: L.latLngBounds([[25.756, -80.244], [25.745, -80.265]]),
    MIRACLE_MILE_IMAGE: L.latLngBounds([[25.7449, -80.2682], [25.7556, -80.2482]])
  };

  export const hoods = {
    WYNWOOD: 'Wynwood',
    MIDTOWN_MIAMI: 'Midtown_Miami',
    COCOWALK: 'CocoWalk',
    MIRACLE_MILE: 'Miracle_Mile',
    MERRICK_PARK: 'Merrick_Park',
    DESIGN_DISTRICT: 'Design_District',
    DADELAND_MALL: 'Dadeland_Mall',
  };

  export const defaultLayers: { [sectionName: string]: string[] } = {};
    cstf.defaultLayers[cst.section.LEAD] = [null];
    cstf.defaultLayers[cst.section.CHALLENGES] = ['all_tweets_blue_small'];
    cstf.defaultLayers[cst.section.OBJECTIVE] = ['all_tweets_gray', 'coral_gables'];
    cstf.defaultLayers[cst.section.HISTORY] = ['old_coral_gables'];
    cstf.defaultLayers[cst.section.CULTURE] = ['instagram'];
    cstf.defaultLayers[cst.section.BUSINESS] = ['business_map'];
    cstf.defaultLayers[cst.section.SOURCES] = ['sample_tweet'];
    cstf.defaultLayers[cst.section.ZONES] = ['zone-food', 'zone-events', 'zone-sports', 'zone-entertainment', 'zone-internet', 'zone-work', 'zone-music'];
    cstf.defaultLayers[cst.section.HEATMAP] = ['heat-positive'];
    cstf.defaultLayers[cst.section.NEIGHBORHOODS] = ['hood_popups', 'all_tweets_blue'];

  // HACK: see flat_map:165
  export const inactiveLayerPostfix = '_inactive';

  const makeShadowLayers = (layer: string) => {
    return values(hoods).filter(n => n !== layer).map(n => n + inactiveLayerPostfix);
  };

  export const shadowLayers = {
    [hoods.WYNWOOD]: makeShadowLayers(hoods.WYNWOOD),
    [hoods.MIDTOWN_MIAMI]: makeShadowLayers(hoods.MIDTOWN_MIAMI),
    [hoods.COCOWALK]: makeShadowLayers(hoods.COCOWALK),
    [hoods.MIRACLE_MILE]: makeShadowLayers(hoods.MIRACLE_MILE),
    [hoods.MERRICK_PARK]: makeShadowLayers(hoods.MERRICK_PARK),
    [hoods.DESIGN_DISTRICT]: makeShadowLayers(hoods.DESIGN_DISTRICT),
    [hoods.DADELAND_MALL]: makeShadowLayers(hoods.DADELAND_MALL),
  };

  export const viewOptionsForSections: { [sectionName: string]: Miami.FlatMapViewOptions } = {};
    cstf.viewOptionsForSections[cst.section.LEAD] = {
      minZoom: 8, maxZoom: 10,
      bounds: L.latLngBounds(L.latLng(25.088, -80.565), L.latLng(26.465, -80.000)),
      padding: { top: 0.06, left: 0.65, bottom: 0.06, right: 0.06 }
    };
    cstf.viewOptionsForSections[cst.section.CHALLENGES] = {
      minZoom: 10, maxZoom: 12,
      bounds: L.latLngBounds(L.latLng(25.977, -80.116), L.latLng(25.645, -80.365))
      // padding: { top: 0.05, left: 0.05, bottom: 0.05, right: 0.05 }
    };
    cstf.viewOptionsForSections[cst.section.OBJECTIVE] = {
      minZoom: 11, maxZoom: 12,
      bounds: L.latLngBounds(L.latLng(25.771, -80.241), L.latLng(25.634, -80.3018))
    };
    cstf.viewOptionsForSections[cst.section.HISTORY] = {
      minZoom: 14, maxZoom: 17,
      bounds: L.latLngBounds(L.latLng(25.736, -80.2836), L.latLng(25.7626, -80.2571))
    };
    cstf.viewOptionsForSections[cst.section.CULTURE] = {
      minZoom: 15, maxZoom: 17,
      bounds: L.latLngBounds(L.latLng(25.744, -80.264), L.latLng(25.755, -80.245))
    };
    cstf.viewOptionsForSections[cst.section.BUSINESS] = {
      minZoom: 15, maxZoom: 17,
      bounds: L.latLngBounds(L.latLng(25.7445, -80.2656), L.latLng(25.7525, -80.2526))
    };
    cstf.viewOptionsForSections[cst.section.SOURCES] = {
      minZoom: 15, maxZoom: 15,
      bounds: L.latLngBounds(L.latLng(25.754, -80.2665), L.latLng(25.755, -80.246))
    };
    cstf.viewOptionsForSections[cst.section.ZONES] = {
      minZoom: 15, maxZoom: 17,
      bounds: L.latLngBounds(L.latLng(25.744, -80.2682), L.latLng(25.756, -80.2475))
    };
    cstf.viewOptionsForSections[cst.section.HEATMAP] = {
      minZoom: 15, maxZoom: 17,
      bounds: L.latLngBounds(L.latLng(25.744, -80.2682), L.latLng(25.756, -80.2475))
    };
    cstf.viewOptionsForSections[cst.section.NEIGHBORHOODS] = {
      minZoom: 12, maxZoom: 13,
      bounds: L.latLngBounds(L.latLng(25.692, -80.3485), L.latLng(25.838, -80.154))
    };

  export const viewOptionsForTransition: Miami.FlatMapViewOptions = {
    specificZoom: 12, center: L.latLng(25.749524, -80.258694)
  };

  export const viewOptionsForLayers: { [sectionName: string]: Miami.FlatMapViewOptions } = {
    'hood_popups': cstf.viewOptionsForSections[cst.section.NEIGHBORHOODS],
    [hoods.WYNWOOD]: {
      minZoom: 14, maxZoom: 16,
      bounds: L.latLngBounds(L.latLng(25.7935, -80.207), L.latLng(25.8135, -80.1915))
    },
    [hoods.MIDTOWN_MIAMI]: {
      minZoom: 14, maxZoom: 16,
      bounds: L.latLngBounds(L.latLng(25.803, -80.196), L.latLng(25.812, -80.1885))
    },
    [hoods.COCOWALK]: {
      minZoom: 14, maxZoom: 16,
      bounds: L.latLngBounds(L.latLng(25.727, -80.244), L.latLng(25.7305, -80.241))
    },
    [hoods.MIRACLE_MILE]: {
      minZoom: 14, maxZoom: 16,
      bounds: L.latLngBounds(L.latLng(25.7471, -80.2635), L.latLng(25.7522, -80.254))
    },
    [hoods.MERRICK_PARK]: {
      minZoom: 14, maxZoom: 16,
      bounds: L.latLngBounds(L.latLng(25.7291, -80.2629), L.latLng(25.734, -80.2575))
    },
    [hoods.DESIGN_DISTRICT]: {
      minZoom: 14, maxZoom: 16,
      bounds: L.latLngBounds(L.latLng(25.8102, -80.1968), L.latLng(25.8163, -80.1885))
    },
    [hoods.DADELAND_MALL]: {
      minZoom: 14, maxZoom: 16,
      bounds: L.latLngBounds(L.latLng(25.6883, -80.3174), L.latLng(25.6923, -80.3065))
    }
  };

  export interface InstagramPhoto {
    latlong: number[];
    img: string;
    url: string;
  }

  export const instagramPhotos: InstagramPhoto[] = [
    { latlong: [25.754246, -80.257785], img: '/images/instagram/coralgableselementary2.jpg', url: 'https://instagram.com/p/bjpEFHoqaE/' },
    { latlong: [25.752469, -80.254459], img: '/images/instagram/coralgate1.jpg', url: 'https://instagram.com/p/8pARfxBsMV/'},
    { latlong: [25.749728, -80.262972], img: '/images/instagram/cityhall1.jpg', url: 'https://instagram.com/p/47DwY8nM8m/' },
    { latlong: [25.750410, -80.260337], img: '/images/instagram/museum1.jpg', url: 'https://instagram.com/p/6dOGyOPzF5/' },
    { latlong: [25.749524, -80.258694], img: '/images/instagram/westincolonnade1.jpg', url: 'https://instagram.com/p/mN4rZtDxLV/' },
    { latlong: [25.752373, -80.256773], img: '/images/instagram/alhambraplaza1.jpg', url: 'https://instagram.com/p/tDLlTfAjW9/' },
    { latlong: [25.748737, -80.260486], img: '/images/instagram/theater1.jpg', url: 'https://instagram.com/p/xFPRSuQKjT/' },
    { latlong: [25.746006, -80.258470], img: '/images/instagram/poncecirclepark1.jpg', url: 'https://instagram.com/p/Ry2zxzpRZJ/' },
    { latlong: [25.749603, -80.247064], img: '/images/instagram/gablesmarquis1.jpg', url: 'https://instagram.com/p/gjRN4wQAgd/'},
    { latlong: [25.750430, -80.249835], img: '/images/instagram/miraclemarketplace1.jpg', url: 'https://instagram.com/p/TjCelLSPxr/'},
    { latlong: [25.748527, -80.254007], img: '/images/instagram/milagro1.jpg', url: 'https://instagram.com/p/rfJjGhLfAA/'},
    { latlong: [25.749850, -80.255555], img: '/images/instagram/davidsbridal1.jpg', url: 'https://instagram.com/p/tQcAqUBb4T/'},
  ];

  export const BUSINESS_ICONS_LIST: {name: string, size: number}[] = [
    { name: 'art', size: 24 },
    { name: 'beauty', size: 24 },
    { name: 'finance-n-estate', size: 24 },
    { name: 'food', size: 24 },
    { name: 'healthcare', size: 24 },
    { name: 'law-n-public', size: 24 },
    { name: 'shopping', size: 24 },
  ];

  export const BUSINESS_ICONS = BUSINESS_ICONS_LIST.reduce((result, i) => {
    result[i.name] = L.icon({
      iconUrl: `/images/business_icons/${i.name}.svg`,
      iconSize: new L.Point(i.size, i.size),
      iconAnchor: new L.Point(i.size / 2, i.size / 2),
    });

    return result;
  }, {} as {[name: string]: L.Icon});

  export const HOOD_POPUPS = [
    { latlong: [25.7985, -80.195], hood: hoods.WYNWOOD}, // real latlong: 25.804, -80.2
    { latlong: [25.81, -80.19], hood: hoods.MIDTOWN_MIAMI}, // real latlong: 25.82, -80.1924
    { latlong: [25.728, -80.240], hood: hoods.COCOWALK}, // real latlong: 25.729, -80.2425
    { latlong: [25.734, -80.26], hood: hoods.MERRICK_PARK}, // real latlong: 25.7317, -80.2602
    { latlong: [25.821, -80.187], hood: hoods.DESIGN_DISTRICT}, // real latlong: 25.813, -80.193
    { latlong: [25.7495, -80.2585], hood: hoods.MIRACLE_MILE}, // real latlong:
    { latlong: [25.6901, -80.3125], hood: hoods.DADELAND_MALL} // real latlong:
  ];

}

export default cstf;
