/// <reference path="../../../typings/tsd.d.ts" />

class ThreeDebugingAxes {

  private COLORS: number[] = [0xff0000, 0x00ff00, 0x0000ff];

  private startingPoint: THREE.Vector3;
  private directions: string;
  private length: number;

  constructor(private scene: THREE.Scene, length?: number, directions?: string, startingPoint?: number[]) {
    this.length = length || 50;
    this.directions = directions || 'both';
    this.startingPoint = new THREE.Vector3(0, 0, 0) || new THREE.Vector3().fromArray(startingPoint);

    for (var i = 0; i < this.COLORS.length; i++) {
      if (this.directions === 'positive')
        this.createSubAxis(i, 1);
      else if (this.directions = 'negative')
        this.createSubAxis(i, 0);
      if (this.directions === 'both')
        this.createSubAxis(i, 0);
        this.createSubAxis(i, 1);
    }
  }

  createSubAxis(i: number, direction: number) {
    var endPoint = [0, 0, 0];
        endPoint[i] = direction === 0 ? -this.length : this.length;
    var endPointVector = new THREE.Vector3().fromArray(endPoint);

    var geo = new THREE.Geometry();
        geo.vertices.push( this.startingPoint.clone() );
        geo.vertices.push( endPointVector.clone() );
        geo.computeLineDistances();

    var mat;
    if (direction === 0) {
      mat = new THREE.LineDashedMaterial({ linewidth: 3, color: this.COLORS[i], dashSize: 2, gapSize: 2 });
    } else {
      mat = new THREE.LineBasicMaterial({ linewidth: 3, color: this.COLORS[i] });
    }

    var mesh = new THREE.LineSegments(geo, mat);
    this.scene.add(mesh);
  }
}

export = ThreeDebugingAxes;
