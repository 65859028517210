var LeafletPopupLayer = L.Class.extend({

  popups: [],
  onClick: null,

  initialize: function(options) {
    this.popups = options.data;
    this.onClick = options.onClick;
  },

  onAdd: function(map) {
    // create a DOM element and put it into one of the map panes
    this.pane = map.getPanes().popupPane;
    for (var i = this.popups.length - 1; i >= 0; i--) {
      var popup = L.popup({ closeButton: false, className: 'hood-popup' })
        .setLatLng(this.popups[i].latlong)
        .setContent(this.popups[i].hood.replace(/_/g, ' '))
        .addTo(map);
      popup._container.setAttribute('data-hood', this.popups[i].hood);
    }
    var self = this;
    this.pane.addEventListener('click', function(e) {
      var target = e.target;
      while (target.parentNode) {
        if (target.className.indexOf('hood-popup') > -1) {
          self.onClick(target.dataset);
          break;
        }
        target = target.parentNode;
      }
    });
  },

  onRemove: function(map) {
    // remove layer's DOM elements and listeners
    while (this.pane.firstChild) {
      this.pane.removeChild(this.pane.firstChild);
    }
  },

  addTo: function(map) {
    map.addLayer(this);
    return this;
  }

});

module.exports = function(args) { return new LeafletPopupLayer(args); };