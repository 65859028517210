/// <reference path="../../typings/tsd.d.ts" />

import * as _ from 'lodash';

class TweetGroup {
  private container: any;
  private tweets: any;

  constructor(el: string, private url: string, private count: number) {
    this.container = d3.select(el);
  }

  loadData(): any {
    if (this.tweets) {
      return Promise.resolve();
    } else {
      let promise = <any>d3.promise;
      return promise.json(this.url).then(data => this.tweets = data);
    }
  }

  display(collection?: string | string[]): any {
    return this.loadData()
      .then(() => {
        let toDisplay = this.filterTweets(collection, this.count);
        this.renderTweets(toDisplay);
      });
  }

  private filterTweets(categories: string | string[], count: number): string[] {
    let tweets = _(this.tweets);
    if (categories) tweets = tweets.pick(categories);

    return <string[]> tweets
      .mapValues((values, key) => {
        return _(values['tweets'])
          .map(tweet => ({category: key, text: tweet}))
          .value();
      })
      .values()
      .flatten()
      .sample(count)
      .map(tweet => this.highlightKeywords(tweet))
      .map(tweet => this.highlightUsernames(tweet))
      .map(tweet => this.highlightHashtags(tweet))
      .value();
  }

  private renderTweets(data: string[]): void {
    let content = this.container
      .selectAll('div.tweet')
      .data(data);

    let tweet = content.enter()
      .append('div')
      .attr('class', 'tweet');

    tweet.append('img')
      .attr('class', 'logo')
      .attr('src', 'images/blue-bird.png')
      .attr('alt', 'twitter-user-picture');

    tweet.append('p');

    content.select('p').html(d => d);

    content.exit().remove();
  }

  private highlightKeywords(tweet: {category: string, text: string}): string {
    let keywords = this.tweets[tweet.category].keywords;
    if (keywords.length < 1) return tweet.text;

    let regex = new RegExp(_(keywords).map(w => `(${w})`).value().join('|'), 'ig');

    return tweet.text
      .replace(regex, `<span class="highlight ${tweet.category}">$&</span>`)
  }

  private highlightUsernames(tweet: string): string {
    return tweet
      .replace(/(\@\w+)/g, '<span class="username">$&</span>');
  }

  private highlightHashtags(tweet: string): string {
    return tweet
      .replace(/(\#\w+)/g, '<span class="hashtag">$&</span>');
  }
}

export default TweetGroup;
