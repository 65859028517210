/// <reference path="../../typings/tsd.d.ts" />

import * as _ from 'lodash';
import cst from './constants';
import cstf from './flat_map_constants';
import cstv from './volumetric_map/volumetric_viz_constants';
import Controller from './controller';
import SentimentsChart from './sentiments_chart';
import NeighbourhoodChart from './neighbourhood_chart';
import * as toggleRadio from './toggle_radio_buttons';
import utils from './utils';

class Layout {
  private $: any = {};
  private RECT: any = {};
  private isAutoScrolling = false;
  private maxScrollDelta = 1000;
  private mapControlsShown = false;

  constructor(private root: Element, private controller: Controller) {
    this.$.storyline = d3.select('#storyline');
    this.$.header = d3.select('header');
    this.$.navigationLinks = d3.selectAll('nav li a');
    this.$.sections = d3.selectAll('.section');
    this.$.titlePart = d3.select('.title-part');
    this.$.maps = d3.select('#maps');
    this.$.mapControls = d3.selectAll('.map-controls');

    this.$.navControls = d3.selectAll('.js-change-section');
    this.$.highlightControls = d3.selectAll('.js-change-highlight');
    this.$.exploreControls = d3.selectAll('.js-toggle-explore');
    this.$.layerControls = this.$.storyline.selectAll('.js-change-layer');
    this.$.datasetControls = this.$.storyline.selectAll('.js-change-dataset');
    this.$.zoomControls = d3.selectAll('.js-map-zoom');
    this.$.mapManualControl = d3.selectAll('.js-map-manual');

    this.saveRECTs();
    this.$.sections.data(d3.values(cst.sectionSettings));
    this.updateSectionsCoords();

    new SentimentsChart({
      el: d3.select('#emotions'),
      dataUrl: 'data/heatmap_categories.csv',
      category: 'category',
      margins: { right: 30 }
    });

    new NeighbourhoodChart({
      el: d3.select('#neighbourhoods'),
      dataUrl: 'data/neighbourhoods.csv',
      category: 'neighbourhood',
      margins: { right: 30 },
      controller: this.controller
    });

    this.controller.on(cst.event.TOTAL_STATE_UPDATE, this.onTotalStateUpdate, this);
    this.controller.on(cst.event.SECTION_CHANGED, this.onSectionChange, this);
    this.controller.on(cst.event.V_EXPLORE_TOGGLED, this.onToggleExplore, this);
    this.controller.on(cst.event.WINDOW_RESIZED, this.onResize, this);
    this.controller.on(cst.event.MAP_MANUAL, this.onMapManual, this);
    this.controller.on(cst.event.MAP_ZOOM_LIMIT, this.onMapZoomLimit, this);
    this.controller.on(cst.event.RECALCULATE_SECTIONS, this.updateSectionsCoords, this);
    this.controller.on(cst.event.SCROLL_TO_SECTION, this.scrollToSection, this);
    window.addEventListener('scroll', this.onScroll.bind(this));

    let self = this;

    this.$.navControls.on('click', function(): void {
      self.controller.changeSection(this.dataset.section);
    }, true);

    this.$.highlightControls.on('click', function(): void {
      if (toggleRadio.isToggleRadioButton(this)) {
        toggleRadio.handleToggleRadioButtonGroup(this);
      }
      const set = this.checked ? this.dataset.set : null;
      const subset = this.checked ? this.dataset.subset : null;
      self.controller.changeHighlight(set, subset);
    }, true);

    this.$.exploreControls.on('click', function(): void {
      let event = <Event>d3.event;
      event.stopPropagation();
      let enable = this.dataset.enable === 'true' ? true : false;

      self.controller.toggleExplore(enable);
    });

    this.$.layerControls.on('click', function(): void {
      let event = <Event>d3.event;
      event.stopPropagation();
      let option: string[] = this.tagName === 'INPUT' ? self.collectInputValues(this, 'layer') : this.dataset.layer.split(',');
      if (_.isEmpty(option)) option = cstf.defaultLayers[self.parentSection(this)];

      self.controller.changeLayer(option);
    });

    this.$.datasetControls.on('click', function(): void {
      let event = <Event>d3.event;
      event.stopPropagation();
      self.onDatasetClick(this);
    });

    this.$.mapManualControl.on('click', function(): void {
      let enable = this.dataset.enable === 'true' ? true : false;
      self.controller.mapManual(enable);
    });

    this.$.zoomControls.on('click', function(): void {
      if (this.classList.contains('js-map-zoom-in')) self.controller.zoomIn();
      if (this.classList.contains('js-map-zoom-out')) self.controller.zoomOut();
    });
  };

  private onDatasetClick(input: HTMLElement): void {
    if (toggleRadio.isToggleRadioButton(input)) toggleRadio.handleToggleRadioButtonGroup(input);

    let volData: Miami.VolumetricData = {
      top: { set: null, subset: null },
      bottom: { set: null, subset: null }
    };

    let checked = document.querySelectorAll(`[name=${input['name']}]:checked`);
    if (checked.length > 0) {
      _.forEach(checked, el => {
        let dataset = <any>el['dataset'];
        if (dataset.layer !== 'both') {
          volData[dataset.layer]['set'] = dataset.set;
          volData[dataset.layer]['subset'] = dataset.subset;
        } else {
          volData.top['set'] = dataset.topSet;
          volData.top['subset'] = dataset.topSubset;
          volData.bottom['set'] = dataset.bottomSet;
          volData.bottom['subset'] = dataset.bottomSubset;
        }
      });
    } else {
      volData = cstv.defaultDataForSections[this.parentSection(input)];
    }

    this.controller.changeDataset(volData);
  }

  private onTotalStateUpdate(state: Miami.State): void {
    this.checkFlatMapButtons(state);
    this.checkVolMapButtons(state);
    // XXX: no map controls popping on these sections for pure prettyness
    const specialSection = cst.sectionsWithoutMapControlsHighlight.indexOf(state.section) >= 0;
    if (specialSection) this.mapControlsShown = true;
    //
    this.onSectionChange(state, true);
    this.onToggleExplore(state);
  }

  private onResize(): void {
    this.saveRECTs();
    this.updateSectionsCoords();
  }

  private saveRECTs(): void {
    this.RECT.document = {};
    this.RECT.document.height = document.documentElement.getBoundingClientRect().height;
    this.RECT.viewport = {};
    this.RECT.viewport.height = document.documentElement.clientHeight;
  }

  private updateSectionsCoords(): void {
    let names: string[] = d3.keys(cst.sectionSettings);
    this.$.sections
      .each(function(d, i): void {
        let scrollTop: number = document.documentElement.scrollTop || document.body.scrollTop;
        let clientRect: any = this.getBoundingClientRect();
        cst.sectionSettings[names[i]].pageOffset = clientRect.top + clientRect.height / 2 + scrollTop;
      });
  }

  private onScroll(): void {
    if (this.isAutoScrolling) return;
    let scrollTop: number = document.documentElement.scrollTop || document.body.scrollTop;
    let viewportCenterPosition: number = this.RECT.viewport.height / 2 + scrollTop;
    let newSectionName: string;

    if (this.isLastSection(scrollTop)) {
      newSectionName = cst.section.CREDITS;
    } else {
      let thisDelta: number, lastDelta: number;

      for (let key of d3.keys(cst.sectionSettings)) {
        thisDelta = Math.abs(viewportCenterPosition - cst.sectionSettings[key].pageOffset);
        if (_.isUndefined(lastDelta) || thisDelta < lastDelta) {
          newSectionName = cst.sectionSettings[key].name;
        }
        lastDelta = thisDelta;
      }
    }
    this.controller.changeSection(newSectionName, false);
  }

  private isLastSection(scrollTop: number): boolean {
    return scrollTop + this.RECT.viewport.height >= this.RECT.document.height - 10;
  }

  private onSectionChange(state: Miami.State, snap: boolean): void {
    if (snap) this.scrollToSection(state.section);
    this.highlightSectionDiv(state.section);
    this.toggleTopNavigationBttns(state.section);
    this.showTitlePart(state.section);
    this.blur(state);
    this.toggleMapControlsVisibility(state.section, state.volExploreMode[state.section]);
  }

  private scrollToSection(section: string): void {
    let scrollTop: number = document.documentElement.scrollTop || document.body.scrollTop;
    let newScrollTop = this.scrollTopForSection(section);

    let delta: number = Math.abs(newScrollTop - scrollTop);
    let time = this.scrollTime(delta);

    d3.transition()
      .duration(time)
      .ease('cubic-in-out')
      .tween('scrolling', () => {
        this.isAutoScrolling = true;
        let interpolator: any = d3.interpolateNumber(scrollTop, newScrollTop);
        return t => window.scrollTo(0, interpolator(t));
      })
      .each('end', () => this.isAutoScrolling = false)
      .each('interrupt', () => this.isAutoScrolling = false);
  }

  private scrollTopForSection(section: string): number {
    return cst.sectionSettings[section].pageOffset - this.RECT.viewport.height / 2;
  }

  private highlightSectionDiv(section: string): void {
    this.$.sections.each(function(d): void {
      d3.select(this.parentNode).classed('active', function(): boolean {
        return _.eq(d.name, section);
      });
    });
  }

  private toggleTopNavigationBttns(section: string): void {
    this.$.navigationLinks.classed('active', function(): boolean {
      let name: string = this.dataset.section;
      return _.includes(cst.sectionBlocks[name], section);
    });
  }

  private showTitlePart(section: string): void {
    this.$.titlePart.classed('invisible', () => _.eq(section, cst.sectionsInOrder[0]));
  }

  private collectInputValues(input: any, val: string): string[] {
    if (toggleRadio.isToggleRadioButton(input)) toggleRadio.handleToggleRadioButtonGroup(input);
    let neighbours = document.querySelectorAll(`[name=${input.name}]:checked`);

    return _.map(neighbours, e => e['dataset'][val]);
  }

  private checkFlatMapButtons(state: Miami.State): void {
    _.forOwn(state.flatLayers, (layers, section) => {
      let layer;
      for (layer of layers) {
        this.$.layerControls
          .filter(`input[data-layer="${layer}"]`)
          .property('checked', function(): boolean {
            if (layers.length > 1 && this.type === 'radio') return false;
            if (this.dataset.layer === layer) return true;
          })
          .attr('data-toggle', function(): boolean {
            if (_.isUndefined(this.dataset.toggle)) return;
            return this.checked;
          });
        ;
      }
    });
  }

  private checkVolMapButtons(state: Miami.State): void {
    _.forOwn(state.volData, (dataset, section) => {
      let inputs = d3.selectAll(`.section[data-section="${section}"] input.js-change-dataset`);
      inputs.property('checked', function(): boolean {
        if (utils.fullDataset(dataset)) {
          return this.dataset.layer === 'both';
        } else {
          let controlDataset: Miami.SetSubset = { set: this.dataset.set, subset: this.dataset.subset };
          return utils.isSameDataset(dataset[this.dataset.layer], controlDataset);
        }
      })
      .attr('data-toggle', function(): boolean {
        if (_.isUndefined(this.dataset.toggle)) return;
        return this.checked;
      });
    });
  }

  private scrollTime(delta: number): number {
    if (delta < this.maxScrollDelta) {
      return cst.duration.MIN_SCROLL_TIME;
    } else {
      return cst.duration.MIN_SCROLL_TIME + (delta - this.maxScrollDelta) / 10;
    }
  }

  private onToggleExplore(state: Miami.State): void {
    d3.select(this.root).classed('explore-mode', state.volExploreMode[state.section]);
    d3.select('html').classed('noscroll', state.volExploreMode[state.section]);
    this.blur(state);
  }

  private blur(state: Miami.State): void {
    let className = Modernizr.cssfilters ? 'blurred' : 'opaque';

    this.$.maps.classed(className, () => {
      return _.includes([cst.section.SUMMARY, cst.section.EXPLORE, cst.section.CREDITS], state.section) && !state.volExploreMode[state.section];
    });
  }

  private parentSection(input: Element): string {
    let section = this.$.sections.filter(function(): boolean {
      return d3.select(this).select(`#${input.id}`).node() !== null;
    });

    return section.attr('data-section');
  }

  private onMapManual(state: Miami.State): void {
    let enable = state.mapManual;

    this.$.mapManualControl
      .attr('data-enable', !enable)
      .classed('locked', enable);

    this.$.storyline
      .classed('locked', enable);

    d3.select('html').classed('noscroll', enable);
    d3.selectAll('article:not(.active), footer').classed('invisible', enable);
    d3.select('article.active').classed('locked', enable);
    this.continueReadingBtn(enable);
    if (!enable) this.updateSectionsCoords();
  }

  private continueReadingBtn(enable: boolean): void {
    if (enable) {
      d3.select('article.active .section')
        .append('button')
        .html('Continue reading')
        .classed('btn btn-wide continue', true)
        .on('click', () => this.controller.mapManual(false));
    } else {
      d3.select('button.continue').remove();
    }
  }

  private onMapZoomLimit(limits: Miami.ZoomLimit): void {
    this.$.zoomControls.filter('.js-map-zoom-in').attr('disabled', limits.in || null);
    this.$.zoomControls.filter('.js-map-zoom-out').attr('disabled', limits.out || null);
  }

  private toggleMapControlsVisibility(section: string, explore: boolean) {
    const shouldHide = cst.sectionsWithoutMapControls.indexOf(section) >= 0 && !explore;
    if (this.$.mapControls.classed('hidden') === shouldHide) return;
    this.$.mapControls
      .classed('hidden', shouldHide)
      .classed('loud', !shouldHide && !this.mapControlsShown);
    if (!shouldHide) this.mapControlsShown = true;
  }
}

export default Layout;
